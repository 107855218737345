import initalState from "./common/initalState";
import TRANSFER_ACTION from "../actions/actionTypes/transferActionType";

export default function transferReducer(
  state = initalState.transferReducer,
  action
) {
  switch (action.type) {
    case TRANSFER_ACTION.SET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: action.payload
      };
    }
    case TRANSFER_ACTION.SET_INTERNAL_TRANSFER_OTP: {
      return {
        ...state,
        transferinfo: action.payload
      };
    }

    default:
      return {
        ...state
      };
  }
}

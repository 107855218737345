import initalState from "./common/initalState";
import SIGN_UP_ACTION from "../actions/actionTypes/signupActionType";

export default function homeReducer(state = initalState.homeReducer, action) {
  switch (action.type) {
    case SIGN_UP_ACTION.SET_SIGN_UP_DETAILS: {
      return {
        ...state,
        signupcredentials: action.payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
}

import NetworkManager from "../utilities/networkManager";
import SIGN_IN_ACTIONS from "./actionTypes/signinActionType";
import { SERVER_URL, SERVER_URL_2, ONBOARDING_URL, TRADE_GOLD_TOKEN } from "../utilities/apiRoutes";
import StorageManager from "../utilities/storageManager";
import { STORAGE_KEYS } from "../utilities/constants";

export function sendSigninCredentials({ email, password }) {
  return async function (dispatch) {
    try {
      const reqData = { email, password };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.SIGN_IN,
        reqData,
        false
      );
      if (response?.data?.status === "Success") {
        dispatch({
          type: SIGN_IN_ACTIONS.SET_SIGN_IN_DETAILS,
          payload: response?.data?.data
        });
        StorageManager.setObject(
          STORAGE_KEYS.LOGIN_TOKEN,
          response?.data?.data
        );
        StorageManager.setObject(STORAGE_KEYS.LOGIN_VERIFICATION, true);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function sendVerificationCredentials({ temp_token, OTP }) {
  return async function (dispatch) {
    try {
      const reqData = { temp_token, OTP };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.VERIFY_OTP,
        reqData,
        false
      );
      if (response?.data?.status == "Success") {
        console.log(response?.data, "data");
        // dispatch({
        //   type: SIGN_IN_ACTIONS.SET_SIGN_IN_DETAILS,
        //   payload: response.data.data,
        // });
        dispatch({
          type: SIGN_IN_ACTIONS.SET_SIGN_IN_DETAILS,
          payload: response?.data?.data
        });
        StorageManager.setObject(
          STORAGE_KEYS.LOGIN_TOKEN,
          response?.data?.data
        );
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function resendOtpCredentials({ temp_token }) {
  return async function (dispatch) {
    try {
      const reqData = { temp_token };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.RESEND_OTP,
        reqData,
        false
      );
      if (response?.data) {
        console.log(response.data, "data");
        // dispatch({
        //   type: SIGN_IN_ACTIONS.SET_SIGN_IN_DETAILS,
        //   payload: response.data.data,
        // });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function requestOtp({ email, purpose }) {
  return async function (dispatch) {
    try {
      const requestData = { email, purpose };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.REQUEST_OTP,
        requestData,
        false
      );
      if (response?.data?.data) {
        dispatch({
          type: SIGN_IN_ACTIONS.SET_TEMP_TOKEN_FOR_REQUEST_OTP,
          payload: response?.data?.data["temp_token"]
        });
      }
      return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  };
}

export function signupVerificationCredentials({
  email,
  password,
  first_name,
  temp_token,
  otp,
  last_name,
  country
}) {
  return async function (dispatch) {
    try {
      const reqData = {
        email,
        password,
        first_name,
        temp_token,
        otp,
        last_name,
        country
      };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.SIGN_UP_VERIFICATION,
        reqData,
        false
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function requestResetPassword({ tempToken, otp, password }) {
  return async function (dispatch) {
    try {
      const requestData = { temp_token: tempToken, otp, password };
      const response = await NetworkManager.post(
        SERVER_URL + ONBOARDING_URL.FORGET_PASSWORD,
        requestData,
        false
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function persistLoginState(newState) {
  return function (dispatch, getState) {
    dispatch({
      type: SIGN_IN_ACTIONS.PERSIST_LOGIN_STATE,
      payload: generatePayload(newState, getState)
    });
  };
}

export function persistLoggedInStatus(newState) {
  return function (dispatch) {
    dispatch({
      type: SIGN_IN_ACTIONS.PERSIST_LOGGED_IN_STATUS,
      payload: newState
    });
  };
}

function generatePayload(state, getState) {
  const existingState = getState()?.signinReducer?.signinDetails;
  return {
    ...existingState,
    ...state
  };
}

// Trade Gold Api
export function tradeGoldToken(token){
  console.log(token)
  return async function (dispatch){
   try{
    const response = await NetworkManager.post(SERVER_URL_2+TRADE_GOLD_TOKEN.LOGIN, token, false);
    return response;
   }catch(err){
    console.log(err)
   } 
  }
}
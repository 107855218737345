import React, { useRef, useEffect } from "react";
import propTypes from "prop-types";
import "./modal.css";


function Modal(props) {

    let modalRef = useRef(null);

    return (
        <div className={"modal-container " + (props.showModal ? "d-block " : "d-none ") + (props.extraClass ? props.extraClass : "")} >
            <div className={"modal-body-container " + (props.customBodyClass ? props.customBodyClass : "")} ref={modalRef}>
                <img src="assets/images/close.svg" className="close-icon" onClick={props.onClose}/>
                {props.children}
            </div>
        </div>
    )
}

Modal.propTypes = {
    showModal: propTypes.bool.isRequired,
    extraClass: propTypes.string,
    customBodyClass: propTypes.string,
    onClose: propTypes.func.isRequired
}

export default Modal;

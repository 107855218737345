import React, { Component } from "react";
import DateComponent from "react-datepicker";

import "./DatePicker.css";
import "react-datepicker/dist/react-datepicker.css";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onCalendarOpen = this.onCalendarOpen.bind(this);
    this.onCalendarClose = this.onCalendarClose.bind(this);
  }

  onChange(value) {
    this.props.onChange(value, this.props.name);
  }

  onCalendarOpen() {
    if (this.props.onCalendarOpen) {
      this.props.onCalendarOpen();
    }
  }

  onCalendarClose() {
    if (this.props.onCalendarClose) {
      this.props.onCalendarClose();
    }
  }

  onBlur(event) {
    return event;
  }

  render() {
    return (
      <div
        className={
          "custom-datepicker " +
          (this.props.className ? this.props.className : "")
        }
      >
        <DateComponent
          dateFormat="dd/MM/yyyy"
          // startDate={new Date()}
          selected={this.props.value || null}
          onChange={this.onChange}
          showPopperArrow={false}
          minDate={this.props.minDate || null}
          maxDate={this.props.maxDate || new Date()}
          placeholderText={this.props.placeholder || ""}
          onCalendarOpen={this.onCalendarOpen}
          onCalendarClose={this.onCalendarClose}
          isClearable={this.props.isClearable}
          disabled={this.props.disabled}
          onBlur={this.onBlur}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </div>
    );
  }
}

export default DatePicker;

import React, { useState, useEffect } from "react";
import propTypes, { func } from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Inputbox from "../InputBox/inputbox";
import "./dropdown.css";

function CustomDropdown(props) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [dropdown, setDropdown] = useState();

  function handleChange(event, index) {
    props.handleChange(event, props.name, index);
  }

  useEffect(() => {
    if (props.val) {
      // props.onClose(props.selectedValue, props.name, props.val);
      setDropdown(props.val);
    }
  }, [props.val]);


  return (
    <Dropdown
      className={
        "custom-dropdown " +
        (props.extraClassName ? props.extraClassName : "") +
        (props.disabled ? " disabled-dropdown " : "")
      }
      style={props.extraStyle ? { ...props.extraStyle } : {}}
      show={props.disabled ? false : isDropdownOpen}
      onToggle={(isOpen, event, metaData) => {
        setDropdownOpen(isOpen);
        if (props.onToggle) {
          props.onToggle(props.name, isOpen, metaData)
        }
        // console.log(dropdown);
        if (!isOpen) {
          // props.onClose(props.selectedValue, props.name);
        }
      }}

    // onBlur={alert("yup")}
    >
      <Dropdown.Toggle
        as="div"
        id="custom-dropdown-id"
        className={
          "custom-dropdown-toggle " +
          (props.buttonTextClass ? props.buttonTextClass : "")
        }
      >
        <div
          className={
            "dropdown-selected " +
            (props.selectedTextClass ? props.selectedTextClass : "")
          }
        >
          <input
            type="text"
            name={props.name}
            value={props.selectedValue ? props.selectedValue : ""}
            placeholder={props.placeholder}
            readOnly={true}
            disabled={props.disabled}
          />
        </div>
        <div className="dropdown-toggle-img">
          <img
            src={
              props.imageSrc
                ? props.imageSrc
                : "assets/images/dropdown_thin_arrow.svg"
            }
            alt=""
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={
          "custom-dropdown-menu " + (props.menuClass ? props.menuClass : "")
        }
      >
        {props.searchable && isDropdownOpen && (
          <Inputbox
            extraClass="searchable-input"
            placeholder="Search"
            name={props.name}
            value={props.searchValue}
            onChangeValue={props.onSearchChange}
          />
        )}
        <React.Fragment>
          {props.options && props.options.length
            ? props.options.map((menuValue, i) => {
              if (typeof menuValue === "object") {
                return (
                  <Dropdown.Item
                    className={
                      "custom-dropdown-item " +
                      (props.extraItemClass ? props.extraItemClass : "")
                    }
                    onClick={() => {
                      handleChange(menuValue.value, props.title);
                    }}
                    key={menuValue.value + "" + i}

                  >
                    {menuValue.img ? <img src={menuValue.img} /> : null}
                    {
                      Array.isArray(menuValue.label) ? menuValue.label.map(label => <span>{label}</span>) : menuValue.label
                    }
                  </Dropdown.Item>
                );
              }
              return (
                <Dropdown.Item
                  className={
                    "custom-dropdown-item " +
                    (props.extraItemClass ? props.extraItemClass : "")
                  }
                  onClick={() => {
                    handleChange(menuValue, props.title);
                  }}
                  key={menuValue + "" + i}
                >
                  {menuValue}
                </Dropdown.Item>
              );
            })
            : []}
        </React.Fragment>
      </Dropdown.Menu>
    </Dropdown>
    // <div style={props.extraStyle ? props.extraStyle : {}}
    //     className={"custom-dropdown" + (props.extraClassName ? props.extraClassName : "")}>
    //     <Dropdown show={isDropdownOpen} onToggle={(isOpen, event, metaData) => {
    //             setDropdownOpen(isOpen);
    //         }}>
    //         <Dropdown.Toggle as="div"
    //             id="custom-dropdown-id"
    //             className={"search-container dropdown-toggle " + (props.buttonTextClass ? props.buttonTextClass : "") + (isDropdownOpen ? " expanded" : "")}>
    //             <div className={"searchbox " + (props.changeTextClass ? props.changeTextClass : "")}>
    //                 <input type="text" value={props.selectedValue ? props.selectedValue : ""} className={(props.textClass ? props.textClass : "")} placeholder={props.placeholder} readOnly={true} />
    //             </div>
    //             <span className="search-img">
    //                 <div className={"dropdown-arrow-down"}></div>
    //             </span>
    //         </Dropdown.Toggle>
    //         <Dropdown.Menu className={"dropdown-menu " + (props.submenuClass ? props.submenuClass : "")}>
    //             {(props.options && props.options.length) ? props.options.map((menuValue, i) => {
    //                 return Array.isArray(menuValue) ? (
    //                     <li className="submenu dropright" key={i}>
    //                         <span>
    //                             <img src="/assets/img/advanced-algo.svg" width={"12px"} height={"10px"} alt={"~"} />
    //                         </span>
    //                         <span className="ml-2">{menuValue[0]}</span>
    //                         <div className="dropdown-menu">
    //                             {menuValue[1].map((item, index) => {
    //                                 return (
    //                                     <a
    //                                         className={"dropdown-item sub-Drop " + (props.TradeDD ? "padding-submenu" : "")}
    //                                         key={index}
    //                                         onClick={() => {
    //                                             handleChange(item, index);
    //                                         }}
    //                                     >
    //                                         {item}
    //                                     </a>
    //                                 );
    //                             })}
    //                         </div>
    //                     </li>
    //                 ) : (
    //                         <Dropdown.Item className={"dropdown-item " + (props.TradeDD ? "extraDropdown" : props.extrachange)} id={menuValue} key={i}
    //                             onClick={() => {
    //                                 handleChange(menuValue, i);
    //                             }}>
    //                             {menuValue}
    //                         </Dropdown.Item>
    //                     );
    //             })
    //                 : []}
    //         </Dropdown.Menu>
    //     </Dropdown>
    // </div>
  );
}

CustomDropdown.propTypes = {
  extraClassName: propTypes.string,
  extraStyle: propTypes.object,
  buttonTextClass: propTypes.string,
  selectedTextClass: propTypes.string,
  selectedValue: propTypes.string.isRequired,
  placeholder: propTypes.string,
  imageSrc: propTypes.string,
  menuClass: propTypes.string,
  options: propTypes.array.isRequired,
  extraItemClass: propTypes.string,
  name: propTypes.string,
  handleChange: propTypes.func.isRequired,
  searchable: propTypes.bool,
  value: propTypes.string,
  onSearchChange: propTypes.func,
  onClose: propTypes.func,
  onToggle: propTypes.func
};

export default CustomDropdown;

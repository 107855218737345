import React, { useEffect, useRef } from "react";
import { setup } from "../utilities/constants";
import * as homeAction from "../actions/homeAction";
import * as proTradeAction from "../actions/proTradeAction";
import * as signinAction from "../actions/signinAction";
import { connect } from "react-redux";
import signalR from "signalr";
import { bindActionCreators } from "redux";
import { socket } from "../realtime";
import StorageManager from "../utilities/storageManager";
import { usePrevious } from "../utilities/customHooks";
import Utility from "../utilities/utility";

const { $ } = window;

function MarketData(props) {
  let accessToken = useRef(null);
  const previousIsLoggedIn = usePrevious(props.isLoggedIn);
  useEffect(() => {
    socketConnection();
    marketDataSocketConnection();
    return () => {
      socket.unsubscribe(
        "unsubscribeFrom_PendingOrderData",
        "subscribeTo_PendingOrderData"
      );
      updatePendingOrderConnection("off");
    };
  }, []);

  useEffect(() => {
    if (previousIsLoggedIn !== props.isLoggedIn && props.isLoggedIn) {
      setAccessToken();
      updatePendingOrderConnection();
    }
  }, [props.isLoggedIn]);
  // UPDATE ORDERS
  function pendingOrderSocketConnection() {
    socket.subscribe(
      "subscribeTo_PendingOrderData",
      accessToken.current,
      "ALL",
      "ALL"
    );
  }
  function updatePendingOrderConnection(status = "on") {
    socket[status]("pushDataAllPendingOrders", handlePendingOrders);
    socket[status]("pushDataTickerPendingOrders", handlePendingOrders);
    if (status === "on") {
      pendingOrderSocketConnection();
    }
  }

  function handlePendingOrders(data) {
    console.log(data, "websocket pending");
    props.orderHistory(data, "WEBSOCKET_RESPONSE");
  }
  // End UPDATE ORDERS

  function socketConnection() {
    socket.subscribe(
      "joinGroup",
      props.tradingPair?.baseCoin || "BTC",
      props.tradingPair?.quoteCoin || "USD",
      50
    );
    updateSocketConnection();
  }

  function marketDataSocketConnection() {
    socket.subscribe("getDataAllMarket");
    updateMarketDataSocketConnection();
  }

  function updateSocketConnection(status = "on") {
    socket[status]("pushDataAllBuy", handleAllBuy);
    socket[status]("pushDataAllSell", handleAllSell);
    socket[status]("pushDataTickerBuy", updatedOrderBuy);
    socket[status]("pushDataTickerSell", updatedOrderSell);
    socket[status]("pushDataAllMatched", handleTradeTape);
    socket[status]("pushDataTickerMatched", updateTradeTape);
  }
  function handleTradeTape(data) {
    props.tradeTapeData(data);
  }
  function updateTradeTape(data) {
    props.updateTradeTapeData(data);
  }
  function updatedOrderBuy(data) {
    props.updateBookBid(data);
  }
  function updatedOrderSell(data) {
    props.updateBookAsk(data);
  }
  function handleAllBuy(data) {
    props.orderBookBid(data);
  }
  function handleAllSell(data) {
    props.orderBookAsk(data);
  }

  function updateMarketDataSocketConnection(status = "on") {
    socket[status]("pushDataAllMarket", updateMarketOrders);
  }

  function updateMarketOrders(data) {
    props.marketcoinList(data);
  }

  function setAccessToken() {
    accessToken.current = StorageManager.getObject("login_token")?.access_token;
  }

  // function updateOrders(data) {
  //   props.orderHistory(data, 'WEBSOCKET_RESPONSE');
  // }
  useEffect(() => {
    // const connection = $.hubConnection(setup.signalR_URL);
    // const dataTickerHubProxy = connection.createHubProxy(setup.signalR_DATAHUB);
    // const access_token = JSON.parse(
    //   window.sessionStorage.getItem("login_token")
    // )?.access_token;
    // connection.start().done(() => {
    //   dataTickerHubProxy.invoke(
    //     "subscribeTo_PendingOrderData",
    //     access_token,
    //     "ALL",
    //     "ALL"
    //   );
    // });
    // dataTickerHubProxy.on("pushDataAllMarket", (data) => {
    //   console.log(data, "market data");
    //   // props.getSocketData(data)
    // });
    // connection.start().done((data) => {
    //   dataTickerHubProxy.invoke("getDataAllMarket");
    // });
  }, []);

  return <React.Fragment />;
}

const mapStateToProps = (state) => {
  return {
    tradingPair: state.proTradeReducer.tradingPair,
    isLoggedIn: state.signinReducer.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, homeAction, proTradeAction, signinAction),
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketData);

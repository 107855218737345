import initalState from "./common/initalState";
import SIGN_IN_ACTION from "../actions/actionTypes/signinActionType";
import {TRADE_GOLD_ACTION}from "./../actions/actionTypes/tradeGoldActionType";

export default function homeReducer(state = initalState.homeReducer, action) {
  switch (action.type) {
    case SIGN_IN_ACTION.SET_SIGN_IN_DETAILS: {
      return {
        ...state,
        signincredentials: action.payload,
      };
    }
    case SIGN_IN_ACTION.SET_SIGN_UP_DETAILS: {
      return {
        ...state,
        signupcredentials: action.payload,
      };
    }

    case SIGN_IN_ACTION.SET_TEMP_TOKEN_FOR_REQUEST_OTP: 
      return {
        ...state,
        requestOtpTempToken: action.payload
      }
  
    case SIGN_IN_ACTION.PERSIST_LOGIN_STATE:
      return {
        ...state,
        signinDetails: action.payload
      }
    
    case SIGN_IN_ACTION.PERSIST_LOGGED_IN_STATUS:
      return {
        ...state,
        isLoggedIn: action.payload
      }
     case TRADE_GOLD_ACTION.LOGIN_KYC_GOLD:
       return {
        ...state,
        loginPush: action.loginPush,
        kycPush: action.kycPush
       } 
    default:
      return {
        ...state,
      };
  }
}

import initalState from "./common/initalState";
import MANAGE_API_KEY_ACTION from "../actions/actionTypes/manageApiKeyActionType";

export default function manageapikeysReducer(
  state = initalState.manageapikeysReducer,
  action
) {
  switch (action.type) {
    case MANAGE_API_KEY_ACTION.GET_API_LIST:
      return {
        ...state,
        apiKeyList: action.payload
      };
    default:
      return {
        ...state
      };
  }
}

import React from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./toasts.css";

const renderInfoMsg = (msg) => {
    return <div className="d-flex align-items-center">
        <span className="message">{msg}</span>
    </div>
}

const renderSuccessMsg = (msg) => {
    return <div className="d-flex align-items-center">
        <span className="message">{msg}</span>
    </div>
}

const renderErrorMsg = (msg) => {
    return <div className="d-flex align-items-center">
        <span className="message">{msg}</span>
    </div>
}

const transition = cssTransition({
    enter: "zoomIn",
    exit: "zoomOut"
});

export default class CustomToasts extends React.Component {
    constructor(props) {
        super(props);
    }
    static info(msg) {
        toast.info(renderInfoMsg(msg), {
            className: "custom-toast-info",
            toastId: "toastInfoId"
        })
    }

    static error(msg) {
        toast.error(renderErrorMsg(msg), {
            className: "custom-toast-error",
            toastId: "toastErrorId"
        })
    }

    static success(msg) {
        toast.success(renderSuccessMsg(msg), {
            className: "custom-toast-success",
            toastId: "toastSuccessId"
        })
    }

    render() {
        return (
            <ToastContainer position="top-center"
                autoClose={3000}
                closeButton={<img src="assets/images/close_white.svg" width="8px" height="8px" className="toast-close-icon" />}
                draggable={false}
                closeOnClick
                hideProgressBar={true}
                pauseOnHover
                className={"custom-toast " + (this.props.extraClass ? this.props.extraClass : "")}
                transition={transition} />
        )
    }
}
export default {
  homeReducer: {
    customerDetails: {},
    walletList: {},
    walletInfo: []
  },
  signupReducer: {},
  kycdetailsReducer: {},
  signinReducer: {
    signinDetails: {},
    isLoggedIn: false,
    loginPush:"",
    kycPush:""
  },
  historyReducer: {},
  transferReducer: {},
  proTradeReducer: {
    tradingPair: {
      baseCoin: "BTC",
      quoteCoin: "USD"
    },
    orderBookAsk: [],
    orderBookBid: []
  },
  manageapikeyReducer: {}
};

import React, { useRef, useState, useEffect } from "react";
import "./header.css";
import {
  Button,
  Dropdown,
  Popovers,
  Toasts,
  Tabs,
  Modal,
  RadioButton
} from "../../ui-kit/index";
import { withRouter } from "react-router-dom";
import { App_Download_Links } from "../../../utilities/constants";
import { Routes } from "../../../routes";
import { Languages } from "../../../utilities/constants";
import { useTranslation } from "react-i18next";
import RenderIf from "../conditional-render/render-if";
import * as signinAction from "../../../actions/signinAction";
import * as homeAction from "../../../actions/homeAction";
import * as settingsAction from "../../../actions/settingsAction";
import * as tradeGoldAction from "../../../actions/tradeGoldAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RenderIfElse from "../conditional-render/render-if-else";
import StorageManager from "../../../utilities/storageManager";
import { Link } from "react-router-dom";

function Header(props) {
  const { t, i18n } = useTranslation(["header", "translation", "home"]);
  const targetRef = useRef(null);
  const [showPopover, setPopover] = useState(false);
  const [showProfile, setProfileover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [translation, setTranslation] = useState(getCurrentLanguage());
  const [popoverContents, setPopoverContents] = useState([
    {
      translate_ref: "app_store",
      label: t("download_links")?.["app_store"],
      href: App_Download_Links.APP_STORE,
      src: "assets/images/apple_logo.svg"
    }
  ]);
  const [appStoreModal, setAppStoreModal] = useState(false);
  const [twoFa, setTwoFa] = useState("");

  const settingsRef = useRef(null);

  function onModalClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!props.tradeType && props.location.pathname === Routes.PRO_TRADE) {
      props.setTradeType(t("home:trade_type")[1]);
    }
  }, [props.tradeType]);

  useEffect(
    function manageDownloadOptions() {
      if (i18n.language === "zh-CN") {
        let downloadOptions = [...popoverContents];
        downloadOptions.unshift({
          translate_ref: "android",
          label: t("download_links")?.android,
          src: "assets/images/android.svg",
          href: App_Download_Links.ANDROID
        });
        downloadOptions = downloadOptions.filter(item => {
          return item.href !== App_Download_Links.GOOGLE_PLAY;
        });
        downloadOptions.forEach(item => {
          item.label = t("download_links")?.[item.translate_ref];
        });
        console.log(downloadOptions);
        setPopoverContents(downloadOptions);
      } else if (i18n.language === "en") {
        let downloadOptions = [...popoverContents];
        downloadOptions.unshift({
          translate_ref: "google_play",
          label: t("download_links")?.["google_play"],
          src: "assets/images/google-play.svg",
          href: App_Download_Links.GOOGLE_PLAY
        });
        downloadOptions = downloadOptions.filter(item => {
          return item.href !== App_Download_Links.ANDROID;
        });
        downloadOptions.forEach(item => {
          item.label = t("download_links")?.[item.translate_ref];
        });
        setPopoverContents(downloadOptions);
      }
      if(props.location.pathname === Routes.PRO_TRADE) {
        props.setTradeType(t("home:trade_type")[1])
      }
    },
    [i18n.language]
  );

  useEffect(() => {
    let twoFaEnabled = props?.customerDetails?.["is2FAEnabled"];
    if (twoFaEnabled) {
      setTwoFa("GAuth");
    }
  }, [props.customerDetails]);

  function getCurrentLanguage() {
    let language;
    Object.keys(Languages).forEach(lang => {
      if (Languages[lang] === i18n.language) {
        language = lang;
      }
    });
    return language;
  }

  function onClickDownloadLinks(linkSrc) {
    if (linkSrc === App_Download_Links.ANDROID) {
      setIsOpen(true);
    } else if (linkSrc === App_Download_Links.APP_STORE) {
      i18n.language === "en"
        ? window.open(App_Download_Links.APP_STORE, "_blank")
        : setAppStoreModal(true);
    } else if (linkSrc === App_Download_Links.GOOGLE_PLAY) {
      window.open(App_Download_Links.GOOGLE_PLAY, "_blank");
    }
  }

  function getAndroidApp() {
    const a = document.createElement("a");
    a.href = App_Download_Links.ANDROID;
    a.download = "download";
    a.click();
  }

  function handleAppStoreModal() {
    let open = !appStoreModal;
    setAppStoreModal(open);
  }

  function radioButtonHandler(event) {
    const { value } = event.target;
    console.log(value);
    setTwoFa(value);
    switch (value) {
      case "GAuth":
        props.history.push(Routes.OTP_AUTH);
        break;
    }
  }

  function onChangePassword() {
    const response = props.requestChangePasswordOtp();
    response.then(response => {
      props.history.push(Routes.CHANGE_PASSWORD);
      if (response?.data?.status === "Error") {
        Toasts.error(response?.data?.message);
      }
    });
  }

  const signout = () => {
    props.PagePush("", "")
    StorageManager.clearStorage();
    props.history.push("/");
  };

  const isProTrade = () => {
    return Routes.PRO_TRADE === props.location.pathname
  };

  return (
    <React.Fragment>
      <Toasts />
      <div className="header-section">
        <div className="container-fluid">
          <div className="head-bg">
            <div className="col-5 nopad left-head">
              <div className="logo" onClick={()=>{
                props.PagePush("", "")
              }}>
                {/* <a href="javascript: return false" onClick={}>
                <img src="/assets/images/coin-logo.png" />
              </a> */}
                <Link to={props.isLoggedIn ? Routes.LOGINHOME : Routes.HOME}>
                  <img src="/assets/images/coin-logo.png" />
                </Link>
              </div>
              {/* <RenderIf condition={props.tradeType === t("home:trade_type")[1]}>
              <Tabs
                type="primary"
                tabsData={t("home:trade_type")}
                activeKey={props.tradeType}
                onSwitchTab={(value) => {
                  props.setTradeType(value);
                  if (value === t("home:trade_type")[0]) {
                    props.history.push(Routes.LOGINHOME);
                  } else if (value === t("home:trade_type")[1]) {
                    props.history.push(Routes.PRO_TRADE);
                    // Toasts.info(t("translation:coming_soon"));
                  }
                  // setTradeType(value);
                }}
              />
            </RenderIf> */}
              {isProTrade() ? (
                <Tabs
                  type="primary"
                  tabsData={t("home:trade_type")}
                  activeKey={props.tradeType}
                  onSwitchTab={value => {
                    props.setTradeType(value);
                    if (value === t("home:trade_type")[0]) {
                      props.history.push(Routes.LOGINHOME);
                    } else if (value === t("home:trade_type")[1]) {
                      props.history.push(Routes.PRO_TRADE);
                      // Toasts.info(t("translation:coming_soon"));
                    }
                    // setTradeType(value);
                  }}
                />
              ) : null}
            </div>
            <div className="col-7 nopad right-head">
              <ul className={(!props.isLoggedIn ? "beforeLogin ": "") + "inline-list header-login"}>
                {props.access_token && (
                  <li onClick={() => props.history.push("/history")}>
                    {t("history")}
                  </li>
                )}
                <li>
                  <Tabs
                    type="secondary"
                    extraClass="translation-tab"
                    tabsData={Object.keys(Languages)}
                    normalClass="translation-tab-normal"
                    activeClass="translation-tab-active"
                    onSwitchTab={value => {
                      setTranslation(value);
                      i18n.changeLanguage(Languages[value]);
                    }}
                    activeKey={translation}
                  />
                </li>
                <RenderIf condition={props.isLoggedIn}>
                  <li
                    onClick={() => {
                      let popover = !showProfile;
                      setProfileover(popover);
                    }}
                  >
                    <span className="prof-img">
                      <img src="assets/images/user.svg" />
                    </span>
                    <span className="prof-name">
                      {props?.customerDetails?.firstName}
                    </span>
                    <i>
                      <img
                        src="assets/images/dropdown_thin_arrow.svg"
                        ref={settingsRef}
                      />
                    </i>
                  </li>
                  <Popovers
                    show={showProfile}
                    placement="bottom-start"
                    targetRef={settingsRef}
                    onHide={() => {
                      setProfileover(false);
                    }}
                  >
                    <ul className="popover-content inline-list header-dropdown">
                      <li>
                        <b>{t("name_label")}</b>
                        <span>{props?.customerDetails?.firstName}</span>
                      </li>
                      <li className="bor-bot">
                        <b>{t("email_id_label")}</b>
                        <span>{props?.customerDetails?.email}</span>
                      </li>
                      {/* {props?.customerDetails?.kycStatus != "Approved" ? ( */}
                      <li
                        className="bor-bot pointer"
                        onClick={() =>
                          props?.customerDetails?.kycStatus != "Approved"
                            ? props.history.push(Routes.ID_VERIFICATION)
                            : null
                        }
                      >
                        {props?.customerDetails?.kycStatus != "Pending" ? (
                          <b>{t("manage_verification")}</b>
                        ) : null}
                        <i>
                          <img src="assets/images/dropdown_thin_arrow.svg" />
                        </i>
                        {props?.customerDetails?.kycStatus == "Approved" ? (
                          <em className="verified">
                            {t("approved")}
                            <i className="pointer">
                              <img src="assets/images/close_white.svg" />
                            </i>
                          </em>
                        ) : null}

                        {props?.customerDetails?.kycStatus == "Pending" ? (
                          <em className="not-verified">
                            {t("pending")}
                            <i className="pointer">
                              <img src="assets/images/close_white.svg" />
                            </i>
                          </em>
                        ) : null}
                        {props?.customerDetails?.kycStatus == "Rejected" ? (
                          <em className="not-verified">
                            {t("rejected")}
                            <i className="pointer">
                              <img src="assets/images/close_white.svg" />
                            </i>
                          </em>
                        ) : null}
                        {props?.customerDetails?.kycStatus ==
                        "Never Submitted" ? (
                          <em className="not-verified">
                            {t("verify_desc")}
                            <i className="pointer">
                              <img src="assets/images/close_white.svg" />
                            </i>
                          </em>
                        ) : null}
                        {/* t("verify_desc") */}
                      </li>
                      ){/* : null} */}
                      {props.tradeType === "Pro trade" && (
                        <li
                          className="bor-bot pointer"
                          onClick={() => {
                            props.history.push(Routes.APIKEY);
                          }}
                        >
                          <b>{t("Manage_API_Keys")}</b>
                          <i>
                            <img src="assets/images/dropdown_thin_arrow.svg" />
                          </i>
                        </li>
                      )}
                      {isProTrade() ? (
                        <li
                          className="bor-bot pointer"
                          onClick={() => {
                            props.history.push(Routes.ADDRESS_BOOK);
                          }}
                        >
                          <b>{t("home:manage_wallet_whitelist")}</b>
                          <i>
                            <img src="assets/images/dropdown_thin_arrow.svg" />
                          </i>
                        </li>
                      ) : null}
                      <li
                        className="bor-bot pointer"
                        onClick={onChangePassword}
                      >
                        <b>{t("change_password")}</b>
                        <i>
                          <img src="assets/images/dropdown_thin_arrow.svg" />
                        </i>
                      </li>
                      <li className="bor-bot pointer">
                        <b>{t("two_fa_auth")}</b>
                        <ul className="auth-list">
                          {/* <li><RadioButton
                            id={"otpId"}
                            type="secondary"
                            name={'two-fa'}
                            label={'One time password'}
                            value={"Otp"}
                            selectedValue={twoFa}
                            onChange={radioButtonHandler} />
                          </li> */}
                          <li>
                            <RenderIfElse
                              condition={
                                props?.customerDetails?.["is2FAEnabled"]
                              }
                            >
                              <div
                                onClick={() => {
                                  props.history.push(Routes.OTP_AUTH);
                                }}
                              >
                                <img
                                  src="assets/images/unlock.svg"
                                  className="pr-1"
                                />{" "}
                                <span>{t("gAuth")}</span>
                              </div>
                              <React.Fragment>
                                <RadioButton
                                  id={"GAuthId"}
                                  type="secondary"
                                  name={"two-fa"}
                                  label={t("gAuth")}
                                  value={"GAuth"}
                                  selectedValue={twoFa}
                                  onChange={radioButtonHandler}
                                />
                              </React.Fragment>
                            </RenderIfElse>
                          </li>
                          {/* <li><RadioButton
                            id={"othersId"}
                            type="secondary"
                            name={'two-fa'}
                            label={'Others'}
                            value={"Others"}
                            selectedValue={twoFa}
                            onChange={radioButtonHandler} />
                          </li> */}
                        </ul>
                      </li>
                      <li className="pointer" onClick={signout}>
                        <b>{t("signout")}</b>
                      </li>
                    </ul>
                  </Popovers>
                </RenderIf>
              </ul>
              <div className="head-txt">
                <RenderIf condition={!props.isLoggedIn}>
                  <label
                    onClick={() => {
                      // Toasts.info(t("translation:coming_soon"));
                      // props.handleModal();
                    }}
                  >
                    <span
                      className="primary-btn"
                      onClick={() => {
                        props.history.push(Routes.SIGN_UP);
                        // Toasts.info(t("translation:coming_soon"));
                        // props.handleModal();
                      }}
                    >
                      {t("sign_up")}
                    </span>
                  </label>
                  <label>
                    <span
                      className="primary-btn"
                      onClick={() => {
                        props.history.push(Routes.LOGIN);
                        // Toasts.info(t("translation:coming_soon"));
                        // props.handleModal();
                      }}
                    >
                      {t("login")}
                    </span>
                  </label>
                </RenderIf>
                <Button
                  button_type="btn-primary"
                  text={t("download")}
                  roundedBtn={true}
                  extraStyle={{ width: "120px" }}
                  ref={targetRef}
                  onClick={() => {
                    let popover = !showPopover;
                    setPopover(popover);
                  }}
                />
                <Popovers
                  targetRef={targetRef}
                  show={showPopover}
                  placement="bottom"
                  onHide={() => {
                    setPopover(false);
                  }}
                >
                  <div className="popover-content">
                    {popoverContents.map((item, index) => {
                      if (item) {
                        return (
                          <div
                            className="popover-item"
                            key={item.label + index}
                          >
                            <img src={item.src} />
                            <a
                              href={undefined}
                              target={"_blank"}
                              onClick={() => {
                                onClickDownloadLinks(item.href);
                              }}
                            >
                              {item.label}
                            </a>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Popovers>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={isOpen}
        customBodyClass="app-store-modal"
        onClose={onModalClose}
      >
        <div className="video-wrapper">
          {/* <img src="assets/images/play-video.png" /> */}
          <video
            src={"https://cyptoassets.blob.core.windows.net/video/Install.mp4"}
            controls
          />
        </div>
        <div className="modal-content">
          <strong>{t("android_modal_text")}</strong>
          {/* <strong>获取iOS访问权限</strong> */}
          {/* <b>简单得像数1·2·3！</b> */}
          {/* <ol>
            <li>使用您的Apple ID注册。</li>
            <li>
              您将收到一封来自“Testflight”的电子邮件，请在您的iPhone上打开该电子邮件。
            </li>
            <li>按照说明下载以访问Cointraders</li>
          </ol> */}
          <Button
            button_type="btn-primary"
            text={t("android_download_link")}
            onClick={getAndroidApp}
          />
        </div>
      </Modal>
      <Modal
        showModal={appStoreModal}
        customBodyClass="app-store-modal"
        onClose={handleAppStoreModal}
      >
        <div className="video-wrapper">
          {/* <img src="assets/images/app_store_default_video.png" /> */}
          <video
            src="https://cyptoassets.blob.core.windows.net/video/install_ios.mov"
            controls
          />
        </div>
        <div className="modal-content">
          {/* <strong>获取iOS访问权限</strong>
          <b>简单得像数1·2·3！</b>
          <ol>
            <li>使用您的Apple ID注册。</li>
            <li>
              您将收到一封来自“Testflight”的电子邮件，请在您的iPhone上打开该电子邮件。
            </li>
            <li>按照说明下载以访问Cointraders</li>
          </ol> */}
          <strong>如何安装iOS内测版</strong>
          {/* <b>简单得像数1·2·3！</b> */}
          <ol>
            <li>在App Store中下载TestFlight。</li>
            成功安装TestFlight后，请回到此页进行下一步操作
            <li>在Test Flight中下载Coin Traders。</li>
            <li>点击“安装”。</li>
            后续有版本更新，可以直接点开TestFlight进行更新，无需重复上述操作。
          </ol>
          <Button
            button_type="btn-primary"
            text={"使用您的Apple ID注册"}
            onClick={() => {
              window.open(App_Download_Links.APP_STORE_CN, "_blank");
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.signinReducer.isLoggedIn,
    customerDetails: state.homeReducer.customerDetails,
    tradeType: state.homeReducer.tradeType
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    Object.assign({}, signinAction, homeAction, settingsAction, tradeGoldAction),
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

import initalState from "./common/initalState";
import HOME_ACTION from "../actions/actionTypes/homeActionType";

export default function homeReducer(state = initalState.homeReducer, action) {
  switch (action.type) {
    case HOME_ACTION.GET_MARKET_LIST: {
      return {
        ...state,
        marketListData: action.payload
      };
    }
    case HOME_ACTION.QRCODE: {
      return {
        ...state,
        qrAddress: action.qrAddress,
        qrURL: action.qrURL
      };
    }
    case HOME_ACTION.BANK_LIST: {
      return {
        ...state,
        bankList: action.payload
      };
    }

    case HOME_ACTION.SET_SETTINGS_DATA:
      return {
        ...state,
        settingsData: action.payload,
        customErrorMessages: action.customErrorMessages,
        tradeSettings: action.tradeSettings
      };

    case HOME_ACTION.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload
      };
    case HOME_ACTION.SET_STATS_LIST:
      return {
        ...state,
        balanceDetails: action.payload
      };
    // case HOME_ACTION.FIAT_DEPOSIT:
    //   return {
    //     ...state,
    //     fiatStatus: action.payload
    //   }
    case HOME_ACTION.WALLET_VALUES:
      return {
        ...state,
        walletList: action.payload
      };
    case HOME_ACTION.SET_CURRENCY_SETTINGS:
      return {
        ...state,
        currencySettings: action.payload
      };
    case HOME_ACTION.SET_WALLET_INFO:
      return {
        ...state,
        walletInfo: action.payload.walletInfo,
        totalBalance: action.payload.totalBalance
      };
    case HOME_ACTION.SET_BUY_SELL_INFO:
      return {
        ...state,
        buySellInfo: action.payload
      };
    case HOME_ACTION.SET_CRYPTO_PRICE:
      return {
        ...state,
        cryptoPrice: action.payload
      };
    case HOME_ACTION.SET_BALANCE_DETAILS:
      return {
        ...state,
        balanceDetails: action.payload
      };
    case HOME_ACTION.SET_MARKETPRICES_MAPPING:
      return {
        ...state,
        marketPricesMapping: action.payload
      };
    case HOME_ACTION.SET_TRADETYPE:
      return {
        ...state,
        tradeType: action.payload
      }
    default:
      return {
        ...state
      };
  }
}

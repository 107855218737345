import React from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import "./popovers.css";
import propTypes from "prop-types";

function CustomPopovers(props) {
    return (<React.Fragment>
        <Overlay target={props.targetRef}
            show={props.show}
            placement={props.placement ? props.placement : "bottom"}
            rootClose={true}
            onHide={props.onHide}>
            {
                ({
                    placement,
                    scheduleUpdate,
                    arrowProps,
                    outOfBoundaries,
                    show: _show,
                    ...remainingProps
                }) => {
                    return (
                        <div {...remainingProps} className="popover-parent">
                            {props.children}
                        </div>
                    )
                }
            }
        </Overlay>
    </React.Fragment>)
}

CustomPopovers.propTypes = {
    targetRef: propTypes.shape({
        current: propTypes.any
    }).isRequired,
    placement: propTypes.oneOf(["bottom", "bottom-start", "bottom-end",
        "left", "left-start", "left-end",
        "right", "right-start", "right-end",
        "top", "top-start", "top-end", "auto",
        "auto-start", "auto-end"]),
    show: propTypes.bool.isRequired,
    onHide: propTypes.func
}

export default CustomPopovers;
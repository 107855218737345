import { combineReducers } from "redux";
import homeReducer from "../homeReducer";
import signupReducer from "../signupReducer";
import signinReducer from "../signinReducer";
import kycdetailsReducer from "../kycdetailsReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import historyReducer from "../historyReducer";
import transferReducer from "../transferReducer";
import proTradeReducer from "../proTradeReducer";
import manageapikeyReducer from "../manageapikeysReducer";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const signinPersistConfig = {
  key: "sign_in",
  storage: storage,
  whitelist: ["signinDetails", "isLoggedIn"]
  // stateReconciler: autoMergeLevel2
};

const kycdetailsPersistConfig = {
  key: "kyc_details",
  storage: storage,
  whitelist: ["setKycDetails"]
};

const homePersistConfig = {
  key: "home",
  storage: storage,
  whitelist: ["customerDetails", "balanceDetails", "currencySettings", "tradeType"]
};
const protradePersistConfig = {
  key: "protrade",
  storage: storage,
  whitelist: []
};

const rootReducer = combineReducers({
  homeReducer: persistReducer(homePersistConfig, homeReducer),
  transferReducer,
  signupReducer,
  historyReducer,
  signinReducer: persistReducer(signinPersistConfig, signinReducer),
  kycdetailsReducer: persistReducer(kycdetailsPersistConfig, kycdetailsReducer),
  proTradeReducer,
  manageapikeyReducer
});

export default rootReducer;

const PRO_TRADE_ACTION_TYPE = {
  SET_TRADING_PAIR: "SET_TRADING_PAIR",
  ORDER_BOOK_ASK: "ORDER_BOOK_ASK",
  ORDER_BOOK_BID: "ORDER_BOOK_BID",
  GET_ORDERS_LIST: "GET_ORDERS_LIST",
  SET_TRADE_DETAIL: "SET_TRADE_DETAIL",
  TRADE_TAPE_DATA: "TRADE_TAPE_DATA",
  SET_IS_OPEN: "SET_IS_OPEN",
  SET_TRADE_HISTORY: "SET_TRADE_HISTORY",
  SET_ORDER_HISTORY: "SET_ORDER_HISTORY",
  UPDATE_ORDERBOOk_SELECTION: "UPDATE_ORDERBOOK_SELECTION",
  UPDATE_DEPTH_CHART: "UPDATE_DEPTH_CHART"
};

export default PRO_TRADE_ACTION_TYPE;

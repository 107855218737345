import React from 'react';
import propTypes from "prop-types";
import "./tabs.css";


function Tabs(props) {

    return (<div className={"Tabs " + (props.extraClass ? props.extraClass : "")} style={props.extraStyle ? props.extraStyle : {}}>
        <div className={"col-12 adjustRow d-flex " + `tabs-border ${props.type}-border ` + (props.borderClass ? props.borderClass : "")}>
            {
                props.tabsData && props.tabsData?.length && props.tabsData?.map((item, index) => {
                    return <div key={item + "" + index} onClick={() => {
                        props.onSwitchTab(item);
                    }} className={props.activeKey === item ? `tabs-active ${props.type}-active ` + (props.activeClass ? props.activeClass : "") : `tabs-normal ${props.type}-normal ` + (props.normalClass ? props.normalClass : "")}>{item}</div>
                })
            }

        </div>
    </div>)
}

Tabs.propTypes = {
    tabsData: propTypes.array.isRequired,
    onSwitchTab: propTypes.func.isRequired,
    activeKey: propTypes.string.isRequired,
    type: propTypes.oneOf(["primary", "secondary", "buy", "sell"]).isRequired,
    extraClass: propTypes.string,
    extraStyle: propTypes.object,
    borderClass: propTypes.string,
    activeClass: propTypes.string,
    normalClass: propTypes.string
}

export default Tabs;
import {TRADE_GOLD_ACTION} from "./actionTypes/tradeGoldActionType";
import axios from "axios";
import {SERVER_URL_2, TRADE_GOLD_TOKEN} from "../utilities/apiRoutes";
import NetworkManger from "../utilities/networkManager";

export function balanceTradeGold(){
    return async function(){
        var tradeToken = window.sessionStorage.getItem("tradeToken");
        try{
            
            //const response = await NetworkManger.get(SERVER_URL_2 + TRADE_GOLD_TOKEN.BALANCE);
            var config = {};
            config["headers"] =  {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tradeToken};
            const response = axios.get(SERVER_URL_2 + TRADE_GOLD_TOKEN.BALANCE, config);
            return response;
        
        }catch(err){
            console.log(err)
        }
    }
}
export function sendNotifyEmail(val){
    let sendEmail = {
        email: val
    }
    return async function(){
        try {
            const response = await NetworkManger.post(SERVER_URL_2 + TRADE_GOLD_TOKEN.SEND_EMAIL, sendEmail, false);
            return response;

        }catch(err){
            console.log(err)
        }
    }
}
export function convertTradeGold(){
    return async function(){
        var tradeToken = window.sessionStorage.getItem("tradeToken");
        try{
            
            //const response = await NetworkManger.get(SERVER_URL_2 + TRADE_GOLD_TOKEN.BALANCE);
            var config = {};
            config["headers"] =  {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tradeToken};
            const response = axios.get(SERVER_URL_2 + TRADE_GOLD_TOKEN.CONVERT, config);
            return response;
        
        }catch(err){
            console.log(err)
        }
    }
}
export function addressGenerate(){
    return async function(){
        var tradeToken = window.sessionStorage.getItem("tradeToken");
        try{
            var config = {};
            config["headers"] =  {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tradeToken};
            const response = axios.post(SERVER_URL_2 + TRADE_GOLD_TOKEN.TOKEN_GENERATE, "", config);
            return response;
        
        }catch(err){
            console.log(err)
        }
    }
}
export function PagePush(login, kyc){
    return function(dispatch){
        try {
            dispatch({
                type: TRADE_GOLD_ACTION.LOGIN_KYC_GOLD,
                loginPush: login,
                kycPush: kyc
            })

        }catch(err){

        }
    }
}
import initalState from "./common/initalState";
import KYC_DETAIL_IN_ACTION from "../actions/actionTypes/kycdetailsActionType";

export default function homeReducer(state = initalState.homeReducer, action) {
  switch (action.type) {
    case KYC_DETAIL_IN_ACTION.PERSIST_KYC_DETAILS_STATE:
      return {
        ...state,
        setKycDetails: action.payload,
      };
    case KYC_DETAIL_IN_ACTION.SET_KYC_DETAILS:
      return {
        ...state,
        kycDetailsList: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}

export const Routes = {
  HOME: "/",
  LOGINHOME: "/home",
  ID_VERIFICATION: "/id-verification",
  SIGN_UP: "/signup",
  LOGIN: "/login",
  FORGET_PASSWORD: "/forgetpassword",
  SET_PASSWORD: "/setpassword",
  VERIFICATION_CODE: "/verificationcode",
  CHANGE_PASSWORD: "/changepassword",
  BANK_ACCOUNT: "/bankaccount",
  CHOOSE_TRANSACTION: "/choosetransaction",
  CONFIRM_TRANSACTION: "/confirmtransaction",
  HISTORY: "/history",
  LAST_TRANSACTION: "/last-transaction",
  DEPOSIT: "/deposit",
  WITHDRAW: "/withdraw",
  TRANSFER: "/transfer",
  OTP_AUTH: "/otpauth",
  ORDER: "/order",
  PRO_TRADE: "/proTrade",
  ADDRESS_BOOK: "/addressBook",
  ADDRESS_MANAGEMENT: "/addressManagement",
  APIKEY: "/apikey",
  GOLDTOKEN: "/goldtoken",
};

import numbro from 'numbro';
import _ from 'lodash';

export const setup = {
  BACKEND_URL: "https://node1.cointraders.io",
  signalR_URL: "https://node1.cointraders.io",
  // signalR_URL: "wss://node1.cointraders.io/signalr/connect",
  signalR_DATAHUB: "dataTickerHub"
};

export const REGEX = {
  email: /\S+@\S+\.\S+/
};

export const PASSWORD_REGEX = {
  password: /^(?=.*\d)(?=.*[A-Z]).{8,15}$/
};

export const STORAGE_KEYS = {
  LOGIN_TOKEN: "login_token",
  LOGIN_VERIFICATION: "login_verify"
};
export const Purpose = {
  FORGOT_PASSWORD: "forgot-password",
  SIGN_UP: "sign-up"
};

export const App_Download_Links = {
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.trade.cointrader",
  ANDROID: "https://androidapk.cointraders.io/android/Cointraders.apk",
  APP_STORE: "https://apps.apple.com/us/app/id1522552675",
  APP_STORE_CN: "https://testflight.apple.com/join/3c6CrKkZ"
};

export const Languages = {
  English: "en",
  Chinese: "zh-CN"
};

export const WidgetId = {
  zh_CN: "6222e590058eda25118022813",
  // en: "53a1cb85ad32d8e6f1ee76b5a",
  en: "99855178798fb158e8c8ab93f"
};

export const Secret_key = {
  KEY_VALUE: "03c06dd7-4982-441a-910d-5fd2cbb3f1c6"
};

export const Trade_Type = {
  MARKET: "MARKET",
  LIMIT: "LIMIT",
  STOP: "STOP"
};

export const Trade_Side = {
  SELL: "SELL",
  BUY: "BUY"
};

export const Google_Authenticator_Links = {
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN",
  APP_STORE: "https://apps.apple.com/in/app/google-authenticator/id388497605"
};

export const Response_Status = {
  SUCCESS: "Success",
  ERROR: "Error"
};

export const Response_Status_Code = {
  SUCCESS: 200
};

export const MARKET_NAME = "USD";

export const RECV_WINDOW = "100000";

export const RESEND_CODE_INTERVAL = 60;

export const Time_In_Force = {
  GTC: "Good till cancelled",
  IOC: "Immediate or cancel",
  FOK: "Fill or kill",
  DO: "Day only"
};

export const getDecimalPrecision = number => {
  let precision = 0;
  let e = 1;

  if (!isFinite(number)) {
    return precision;
  }

  while (Math.round(e * number) / e !== number) {
    e *= 10;
    precision++;
  }

  return precision;
};

export const trimNumber = (number = 0, decimalPlaces) => {
  const currentPrecision = getDecimalPrecision(number);
  if (currentPrecision > decimalPlaces) {
    number = parseFloat(number);
    number =
      Math.floor(number * Math.pow(10, decimalPlaces)) /
      Math.pow(10, decimalPlaces);
  }

  return number;
};

export const formatNumberToPlaces = (
  number = 0,
  decimalPrecision = 8,
  options = {}
) => {
  const { trim = true, thousandSeparated = true } = options;
  number = number ? number : 0;

  if (isNaN(parseFloat(number))) {
    return number;
  }

  number = trimNumber(parseFloat(number), decimalPrecision);

  return numbro(number).format({
    trimMantissa: trim,
    mantissa: decimalPrecision,
    thousandSeparated
  });
};


  export const formatCrypto = (number = 0, trim = false) => {
    number = number ? number : 0;
  
    if (isNaN(parseFloat(number))) {
      return number;
    }
  
    return numbro(number).format({
      trimMantissa: trim,
      mantissa: 8,
    });
  };
  
class NumberParser {
  constructor(locale) {
    this.setLocale(locale);
  }

  setLocale(locale) {
    let parts;
    if (Intl.NumberFormat.prototype.formatToParts) {
      parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    } else {
      parts = [
        {
          type: "integer",
          value: "12"
        },
        {
          type: "group",
          value: ","
        },
        {
          type: "integer",
          value: "345"
        },
        {
          type: "decimal",
          value: "."
        },
        {
          type: "fraction",
          value: "6"
        }
      ];
    }
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      )
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this.decimalSeparator = parts.find(d => d.type === "decimal").value;
    this.thousandSeparator = parts.find(d => d.type === "group").value;
    this._group = new RegExp(
      `[${parts.find(d => d.type === "group").value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts.find(d => d.type === "decimal").value}]`
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d);
  }

  setDelimiters({ thousandSeparator, decimalSeparator }) {
    this._decimal = new RegExp(`[${decimalSeparator}]`);
    this._group = new RegExp(`[${thousandSeparator}]`, "g");
    this.decimalSeparator = decimalSeparator;
    this.thousandSeparator = thousandSeparator;
  }

  getDelimiters() {
    return {
      thousandSeparator: this.thousandSeparator,
      decimalSeparator: this.decimalSeparator
    };
  }

  parse(string) {
    string = _.isNumber(string) ? string.toString() : string ? string : "0";
    // eslint-disable-next-line no-cond-assign
    return (string = string
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index))
      ? string
      : NaN;
  }
}

export const numberParser = new NumberParser("en");

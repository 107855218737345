import NetworkManager from "../utilities/networkManager";
import HOME_ACTIONS from "./actionTypes/homeActionType";
import QRCode from "qrcode";

import {
  SERVER_URL,
  MARKET_VIEW_URL,
  SETTINGS_URL,
  ONBOARDING_URL,
  DEPOSIT
} from "../utilities/apiRoutes";
import { setup, RECV_WINDOW, Response_Status } from "../utilities/constants";
import Utility from "../utilities/utility";
import NetworkManger from "../utilities/networkManager";

export const marketcoinList = data => {
  return dispatch => {
    dispatch({
      type: HOME_ACTIONS.GET_MARKET_LIST,
      payload: data
    });
  };
};
export const sendWalletValues = walletData => {
  return dispatch => {
    dispatch({
      type: HOME_ACTIONS.WALLET_VALUES,
      payload: walletData
    });
  };
};
export function getSocketData(data) {
  //console.log(data);
}

export function getSettingsData() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        setup.BACKEND_URL + SETTINGS_URL.GET_SETTINGS,
        false
      );
      if (response?.data?.status === Response_Status.SUCCESS) {
        dispatch({
          type: HOME_ACTIONS.SET_SETTINGS_DATA,
          payload: response?.data?.data,
          customErrorMessages: response?.data?.data?.customErrorMessages,
          tradeSettings: response?.data?.data?.trade_setting
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function getCustomerDetails() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        SERVER_URL +
          ONBOARDING_URL.GET_CUSTOMER_DETAILS +
          `?timestamp=${Utility.generateTimestamp()}&recvWindow=${RECV_WINDOW}`,
        true
      );
      if (response?.data?.status === "Success") {
        dispatch({
          type: HOME_ACTIONS.SET_CUSTOMER_DETAILS,
          payload: response.data.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function getBalanceList() {
  const requestData = {
    currency: "ALL",
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW
  };
  return async function (dispatch) {
    try {
      const response = await NetworkManger.post(
        SERVER_URL + ONBOARDING_URL.GET_BALANCE_DETAILS,
        requestData,
        true
      );
      if (response?.data?.status === "Success") {
        dispatch({
          type: HOME_ACTIONS.SET_STATS_LIST,
          payload: response.data.data
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function generateAddress(currencyName) {
  const requestData = {
    currency: currencyName,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW
  };
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + DEPOSIT.DEPOSIT_URL,
        requestData,
        true
      );
      QRCode.toDataURL(response && response?.data?.data?.address)
        .then(url => {
          dispatch({
            type: HOME_ACTIONS.QRCODE,
            qrAddress: response && response.data.data.address,
            qrURL: url
          });
        })
        .catch(err => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
}

export function fiatDeposit(requestData) {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + DEPOSIT.FIAT_DEPOSIT,
        requestData,
        true,
        {},
        true
      );
      return response;
      // dispatch({
      //   type: HOME_ACTIONS.FIAT_DEPOSIT,
      //   payload: response.data
      // })
    } catch (err) {
      console.log(err);
    }
  };
}

export function getBankList() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        SERVER_URL + DEPOSIT.BANK_LIST,
        true
      );
      if (response?.data?.status === "Success") {
        dispatch({
          type: HOME_ACTIONS.BANK_LIST,
          payload: response.data.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function getCurrencySettings() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        SERVER_URL + SETTINGS_URL.CURRENCY_SETTINGS,
        false,
        {},
        true
      );
      if (response?.data?.status === "Success") {
        dispatch({
          type: HOME_ACTIONS.SET_CURRENCY_SETTINGS,
          payload: response.data.data
        });
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function setWalletInfo({ walletInfo, totalBalance }) {
  return function (dispatch) {
    dispatch({
      type: HOME_ACTIONS.SET_WALLET_INFO,
      payload: {
        walletInfo,
        totalBalance
      }
    });
  };
}

export function setBuySellInfo(buySellInfo) {
  return function (dispatch) {
    dispatch({
      type: HOME_ACTIONS.SET_BUY_SELL_INFO,
      payload: buySellInfo
    });
  };
}

export function getCryptoPrice() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        SERVER_URL + ONBOARDING_URL.GET_CRYPTO_PRICE,
        false
      );
      if (response?.data?.status === Response_Status.SUCCESS) {
        dispatch({
          type: HOME_ACTIONS.SET_CRYPTO_PRICE,
          payload: response.data.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function setBalanceDetails(balanceDetail) {
  return function (dispatch) {
    dispatch({
      type: HOME_ACTIONS.SET_BALANCE_DETAILS,
      payload: balanceDetail
    });
  };
}

export function setMarketPricesMapping(marketPrices) {
  return function (dispatch) {
    dispatch({
      type: HOME_ACTIONS.SET_MARKETPRICES_MAPPING,
      payload: marketPrices
    });
  };
}

export function setTradeType(type) {
  return function (dispatch) {
    dispatch({
      type: HOME_ACTIONS.SET_TRADETYPE,
      payload: type
    });
  };
}

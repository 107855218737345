import React from "react";
import propTypes from "prop-types";
import "./uploadbox.css";

const Uploadbox = React.forwardRef((props, ref) => {
  return (
    <div
      className={
        "uploadbox " +
        (props.extraClass ? props.extraClass : "") +
        (props.disabled ? "disabled " : "")
      }
      style={props.extraStyle ? props.extraStyle : {}}
    >
      <div className={props.disabled ? "disabled" : ""}>
        <img src="assets/images/upload_image.svg" alt="" />
        <span className="upload-label">{props.label}</span>
      </div>
      <span
        className={"upload-link " + (props.disabled ? "disabled" : "")}
        onClick={() => {
          if (ref) {
            ref.current.click();
          }
        }}
      >
        {props.browseLabel}
      </span>
      <input
        type="file"
        disabled={props.disabled}
        ref={ref}
        name={props.name}
        title={props.title}
        onChange={props.onChangeFileHandler}
        accept={props.isOnlyImage ? "image/jpeg,image/png" : ""}
        onClick={props.onClick}
      />
    </div>
  );
});

Uploadbox.propTypes = {
  label: propTypes.string.isRequired,
  extraClass: propTypes.string,
  disabled: propTypes.bool,
  ref: propTypes.shape({
    current: propTypes.any
  }).isRequired,
  onChangeFileHandler: propTypes.func.isRequired,
  extraStyle: propTypes.object,
  isOnlyImage: propTypes.bool,
  browseLabel: propTypes.string
};

export default Uploadbox;

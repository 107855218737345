import initalState from "./common/initalState";
import HISTORY_ACTION from "../actions/actionTypes/historyActionType";

export default function historyReducer(
  state = initalState.historyReducer,
  action
) {
  switch (action.type) {
    case HISTORY_ACTION.SET_TRANSACTION_HISTORY: {
      return {
        ...state,
        overallhistory: action.payload,
      };
    }
    case HISTORY_ACTION.SET_INTERNALTRANSFER_HISTORY: {
      return {
        ...state,
        transferhistory: action.payload,
      };
    }

    case HISTORY_ACTION.SET_WITHDRAWAL_HISTORY: {
      return {
        ...state,
        withdrawalhistory: action.payload,
      };
    }

    case HISTORY_ACTION.SET_DEPOSIT_HISTORY: {
      return {
        ...state,
        deposithistory: action.payload,
      };
    }
    case HISTORY_ACTION.SET_FIAT_DEPOSIT_HISTORY: {
      return {
        ...state,
        fiatdeposithistory: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
}

import React from "react";
import Icon from "../Icon/icon";
import propTypes from "prop-types";
import "./inputbox.css";

const Inputbox = React.forwardRef(function Inputbox(props, ref) {
  function onChangeValue(e) {
    let value = e.target.value;
    let isTrue = true;
    if (
      typeof props.restrictPrecision === "string" ||
      typeof props.restrictPrecision === "number"
    ) {
      let index = value.indexOf(".");
      let precisionLength = index > -1 ? value.slice(index + 1).length : 0;
      isTrue = isTrue && precisionLength <= props.restrictPrecision ? true : false;
      // props.onChangeValue(value, e.target.name);
    }
    if (props.type === "tel") {
      let regexPattern = /^[0-9]*$/;
      // props.onChangeValue(value, e.target.name);
      isTrue = isTrue && regexPattern.test(value);
    }
    if (props.isAlphaNum) {
      let regexPattern = /^[0-9A-Za-z ]*$/;
      // props.onChangeValue(value, e.target.name);
      isTrue = isTrue && regexPattern.test(value);
    }
    if (props.isPositiveNumber) {
      isTrue = isTrue && value >= 0 ? true : false;
    }
    if (isTrue) {
      props.onChangeValue(value, e.target.name, props.index, e.target.title);
    }
  }

  return (
    <div
      className={
        "input-box  " +
        (props.parentClass ? props.parentClass : "") +
        (props.disabled ? " disabled" : "") +
        (props.error ? " errorField" : "") +
        (props.success ? " successField" : "")
      }
      id={props.parentID ? props.parentID : ""}
    >
      {/* {props.phoneType === "tel" ? <span className="tel-code">
            {"+" + props.phoneCode}
        </span> : null} */}
      <input
        className={
          "input_fields " +
          (props.extraClass ? props.extraClass : "") +
          (props.disabled ? " disabled-input-field" : "")
        }
        ref={ref}
        placeholder={props.placeholder ? props.placeholder : ""}
        disabled={props.disabled ? props.disabled : false}
        type={props.type ? props.type : "text"}
        id={props.id ? props.id : ""}
        name={props.name ? props.name : ""}
        title={props.title ? props.title : ""}
        onChange={onChangeValue}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        onFocus={props.onFocus}
        value={props.value}
        maxLength={props.maxLength ? props.maxLength : ""}
        min={props.minValue}
        max={props.maxValue}
        autoComplete={props.autoComplete}
        defaultValue={props.defaultValue}
        readOnly={props.readOnly ? true : false}
        step={props.step ? props.step : "any"}
        minLength={props.minLength ? props.minLength : ""}
      />
      {props.reset ? (
        <Icon
          img_src="assets/images/close.svg"
          extraStyle={{
            width: "auto",
            marginRight: "15px",
          }}
          onClick={() => {
            props.onReset(props.name);
          }}
          disabled={props.disabled}
        />
      ) : null}
      {props.imgSrc ? (
        <Icon
          img_src={props.imgSrc}
          onClick={() => {
            props.imageClick(props.name);
          }}
          extraClass={props.imageExtraClass}
          extraStyle={{
            marginRight: "15px",
            ...props.imageExtraStyle,
          }}
          disabled={props.disabled}
        />
      ) : null}
    </div>
  );
});

Inputbox.propTypes = {
  parentClass: propTypes.string,
  disabled: propTypes.bool,
  error: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  success: propTypes.bool,
  extraClass: propTypes.string,
  placeholder: propTypes.string,
  type: propTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  name: propTypes.string,
  value: propTypes.string,
  maxLength: propTypes.number,
  id: propTypes.string,
  onChangeValue: propTypes.func,
  onBlur: propTypes.func,
  onKeyPress: propTypes.func,
  onFocus: propTypes.func,
  parentId: propTypes.string,
  defaultValue: propTypes.string,
  minValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
  maxValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
  reset: propTypes.bool,
  onReset: propTypes.func,
  imgSrc: propTypes.string,
  imageClick: propTypes.func,
  imageExtraClass: propTypes.string,
  imageExtraStyle: propTypes.object,
  readOnly: propTypes.bool,
  isAlphaNum: propTypes.bool,
  step: propTypes.oneOfType([propTypes.string, propTypes.number]),
  minLength: propTypes.number,
  isPositiveNumber: propTypes.bool,
  restrictPrecision: propTypes.oneOfType([propTypes.string, propTypes.number])
};

Inputbox.displayName = "Inputbox";

export default Inputbox;

import React from "react";
import propTypes from "prop-types";

function ShortLoader(props) {
    return(<span className={"short-loader ml-2 " + (props.extraClass ? props.extraClass : "") + (props.show ? " d-inline-block" : " d-none")}></span>)
}


ShortLoader.propTypes = {
    extraClass: propTypes.string,
    show: propTypes.bool
}

export default ShortLoader;

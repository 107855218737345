import React from "react";
import "./loading.css";
import Spinner from "react-spinkit";

function Loading(props) {
  return (
    <div
      className={"sweet-loading " + (props.className ? props.className : "")}
      id={props.id ? props.id : ""}
      style={props.customStyle ? props.customStyle : {}}
    >
      <Spinner
        name="circle"
        color="#ffffff"
        fadeIn={"none"}
        overrideSpinnerClassName="loading"
      />
    </div>
  );
}
export default Loading;

import React, { useState, useEffect } from "react";
import Dropdown from "../Dropdown/dropdown";
import InputBox from "../InputBox/inputbox";
import "./dropdownInput.css";
import Select from "react-select";

function DropdownInput(props) {
  const [customOptions, setCustomOptions] = useState([]);
  return (
    <div className="inputdropdown main-drop">
      {/* <Dropdown
        extraClassName={
          "inputdropdown-ddbox " +
          (props.ddExtraClass ? props.ddExtraClass : "")
        }
        buttonTextClass="ddbox-toggle"
        options={props.options ? props.options : []}
        handleChange={(value, name) => {
          props.onChangeDDValue(value, name);
        }}
        selectedValue={props.selectedValue}
        placeholder={props.ddPlaceholder}
        name={props.ddName}
      /> */}
      <Select
        className={
          "inputdropdown-ddbox " +
          (props.ddExtraClass ? props.ddExtraClass : "")
        }
        value={props.selectedValue}
        onChange={(value, name) => {
          props.onChangeDDValue(value, name);
        }}
        options={props.options ? props.options : []}
        // isDisabled={props.disabled}
        isSearchable={true}
        placeholder={props.ddPlaceholder}
        name={props.ddName}
        classNamePrefix="custom-select"
      />
      <div className="divider"></div>
      <InputBox
        parentClass={
          "inputdropdown-inputbox " +
          (props.inputExtraClass ? props.inputExtraClass : "")
        }
        type={props.inputType}
        value={props.inputValue}
        onChangeValue={(value, name) => {
          props.onChangeInputValue(value, name);
        }}
        placeholder={props.inputPlaceholder}
        name={props.inputName}
        maxLength={props.maxLength}
        onBlur={props.onBlur}
      />
    </div>
  );
}

export default DropdownInput;

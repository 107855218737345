import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as homeAction from "../../actions/homeAction";
import * as signinAction from "../../actions/signinAction";
import { MARKET_NAME } from "../../utilities/constants";
import { usePrevious } from "../../utilities/customHooks";
import Utility from "../../utilities/utility";
import orderBy from "lodash/orderBy"

function FormatMarketData(props) {
  const precision = 2;

  const [currencyFullName, setCurrencyFullName] = useState({});

  const previousMarketListData = usePrevious(props.marketListData);
  const previousBalanceDetails = usePrevious(props.balanceDetails);
  const previousCurrencySettings = usePrevious(props.currencySettings);


  useEffect(() => {
    props.getCryptoPrice();
    props.getCurrencySettings();
  }, []);

  useEffect(() => {
    props.getBalanceList();
  }, [props.isLoggedIn]);

  useEffect(() => {
    if (!Utility.compareObjects(props.balanceDetails, previousBalanceDetails) || !Utility.compareObjects(props.marketListData, previousMarketListData) && (props.marketListData && props.balanceDetails)) {
      let usdMarketData = filterUsdMarketData();
      let marketData = {};
      let wallet;
      let totalBalance = 0;
      usdMarketData &&
        usdMarketData.map((details) => {
          marketData[details.CoinName] = {
            currentTradingPrice: details.CurrentTradingPrice,
            changeInPrice: details.ChangeInPrice.toFixed(precision),
          };
        });
      wallet =
        props.balanceDetails &&
        props.balanceDetails.map((balance) => {
          let currentTradingPrice =
            balance.currency === MARKET_NAME
              ? 1.0
              : marketData[balance.currency]?.currentTradingPrice
              ? marketData[balance.currency]?.currentTradingPrice
              : getCurrentTradingPrice(balance.currency);
          let yourBalance = currentTradingPrice
            ? balance.balance * currentTradingPrice
            : balance.balance * 0;
          totalBalance += parseFloat(yourBalance);
          yourBalance = yourBalance.toFixed(precision);
          return {
            balance: balance.currency === MARKET_NAME ? "-" : balance.balance,
            coinName: balance.currency,
            yourBalance: parseFloat(yourBalance),
            fullName: currencyFullName[balance.currency],
            changeInPrice: marketData[balance.currency]?.changeInPrice,
            currentTradingPrice: parseFloat(currentTradingPrice).toFixed(
              precision
            ),
          };
        });
      wallet = orderBy(wallet, 'yourBalance', 'desc');
      console.log(wallet);
      totalBalance = totalBalance.toFixed(2);
      props.setWalletInfo({walletInfo: wallet, totalBalance});
    }
  }, [props.marketListData, props.balanceDetails]);

  useEffect(() => {
    if (props.currencySettings) {
      let fullNames = {};
      props.currencySettings.forEach((currency) => {
        fullNames[currency.shortName] = currency.fullName;
      });
      setCurrencyFullName(fullNames);
    }
  }, [props.currencySettings]);

  useEffect(() => {
    let availableCurrency = {};
    let balanceDetails = [];
    if ( !Utility.compareObjects(previousCurrencySettings, props.currencySettings) || !Utility.compareObjects(previousBalanceDetails, props.balanceDetails) && (props.currencySettings && props.balanceDetails)) {
      props.currencySettings.forEach((currency) => {
        availableCurrency[currency.shortName] = true;
      });
      balanceDetails = props.balanceDetails?.filter((balance) => {
        return availableCurrency[balance.currency];
      });
      props.setBalanceDetails(balanceDetails);
    }
  }, [props.currencySettings, props.balanceDetails]);

  useEffect(() => {
    if (!Utility.compareObjects(previousMarketListData, props.marketListData)) {
      let marketPrices = {};
      props.marketListData &&
        props.marketListData.map((marketData) => {
          const {
            MarketName,
            CoinName,
            CurrentTradingPrice,
            MakerFee,
            TakerFee,
            MinTradeAmount,
            MinTickSize
          } = marketData;
          if (!marketPrices[MarketName]) {
            marketPrices[MarketName] = {};
          }
          marketPrices[MarketName][CoinName] = {
            tradePrice: CurrentTradingPrice,
            makerFee: MakerFee,
            takerFee: TakerFee,
            minOrderValue: MinTradeAmount,
            minTickSize: MinTickSize
          };
        });
        props.setMarketPricesMapping(marketPrices);
    }
  }, [props.marketListData]);

  function filterUsdMarketData() {
    return props?.marketListData?.filter(
      (marketData) => marketData.MarketName === MARKET_NAME
    );
  }

  function getCurrentTradingPrice(coinName) {
    if (props.cryptoPrice) {
      let { rateList } = props.cryptoPrice;
      let currentTradingPrice = rateList?.filter((rate) => {
        return rate.currency === coinName;
      })[0];
      return currentTradingPrice?.rate;
    }
  }

  return <React.Fragment />;
}

const mapStateToProps = (state) => {
  return {
    marketListData: state.homeReducer.marketListData,
    balanceDetails: state.homeReducer.balanceDetails,
    cryptoPrice: state.homeReducer.cryptoPrice,
    currencySettings: state.homeReducer.currencySettings,
    isLoggedIn: state.signinReducer.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    Object.assign({}, homeAction, signinAction),
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatMarketData);

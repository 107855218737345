import ProTradeActionType from "./actionTypes/proTradeActionType";
import NetworkManager from "../utilities/networkManager";
import {
  SERVER_URL,
  PROTRADE_URL,
  ADDRESS_BOOK_URL,
  TRANSACTION_HISTORY_URL,
} from "../utilities/apiRoutes";
import { RECV_WINDOW, Response_Status } from "../utilities/constants";
import Utility from "../utilities/utility";
import _, { filter } from "lodash";
import moment from "moment";
export function setTradingPair(pair) {
  return function (dispatch) {
    dispatch({
      type: ProTradeActionType.SET_TRADING_PAIR,
      payload: pair,
    });
  };
}

const formatOrderBookItem = (orderBookItem) => ({
  price: orderBookItem.Rate,
  volume: orderBookItem.Volume,
});

const formatOrderBook = (orderBook) => {
  const formattedOrderBook = orderBook.map((orderBookItem) =>
    formatOrderBookItem(orderBookItem)
  );

  return _.orderBy(formattedOrderBook, "price", "desc");
};
const getMaxVolume = (vloumeData) => {
  const maxVolumeItem = _.maxBy([...vloumeData], "volume") || {};
  const maxVolume = _.get(maxVolumeItem, "volume");
  return maxVolume;
};
const getNewOrderBook = (orderBook, singleItem, action) => {
  if (action === "Insert") {
    orderBook.push(singleItem);
  } else {
    const index = _.findIndex(orderBook, ["price", singleItem.price]);

    if (index >= 0) {
      if (action === "Update") {
        orderBook[index] = singleItem;
      } else if (action === "Delete") {
        orderBook.splice(index, 1);
      }
    } else {
      if (singleItem.volume) {
        orderBook.push(singleItem);
      }
    }
  }

  return _.orderBy(orderBook, "price", "desc");
};

// tradeTape intitalize and update
const formatTradeHistory = (tradeHistoryItem) => {
  const { ExecutionType, OrderConfirmDate, Volume, Rate } = tradeHistoryItem;

  return {
    time: moment.utc(OrderConfirmDate).local().format("HH:mm:ss"),
    side: ExecutionType,
    price: Rate,
    volume: Volume,
  };
};

export function tradeTapeData(data) {
  const formattedTradeHistory = data.map((singleTradeHistory) =>
    formatTradeHistory(singleTradeHistory)
  );
  return function (dispatch) {
    dispatch({
      type: ProTradeActionType.TRADE_TAPE_DATA,
      payload: formattedTradeHistory,
    });
  };
}

export function orderHistory(data, type) {
  return function (dispatch, getState) {
    let existingOrders = Utility.deepCopyObject(
      getState().proTradeReducer.orderhistory
    );
    let processedOrders = processOrderHistoryData(data, existingOrders, type);
    dispatch({
      type: ProTradeActionType.SET_ORDER_HISTORY,
      payload: processedOrders,
    });
  };
}

function processOrderHistoryData(orders, existingOrders, type) {
  let pendingOrders = Array.isArray(existingOrders) ? [...existingOrders] : [];
  let date;
  // if(type === 'HTTP_RESPONSE' && orders) {
  //   let orderHistory = orders;
  //   pendingOrders = orderHistory && orderHistory?.filter(order => {
  //     return order.orderStatus === "Pending";
  //   });
  // }
  if (orders?.TriggerType === "Update") {
    let order = orders?.Data;
    console.log("order", order);
    if (order.OrderStatus) {
      pendingOrders = pendingOrders.filter((pendingOrder) => {
        return order.OrderID !== pendingOrder.OrderID;
      });
      console.log(pendingOrders, "order");
    } else {
      pendingOrders.push(orders?.Data);
    }
  } else {
    let oldOrders = {};
    let orderId;
    pendingOrders.forEach((order) => {
      orderId = order.orderId || order.OrderID;
      oldOrders[orderId] = order;
    });
    Array.isArray(orders) &&
      orders.forEach((order) => {
        if (!oldOrders[order.OrderID]) {
          pendingOrders.push(order);
        }
      });
  }
  pendingOrders = _.orderBy(
    pendingOrders,
    (order) => {
      date = order.date || order.OrderConfirmDate || order.OrderPlacementDate;
      return moment(date).unix();
    },
    "desc"
  );
  return pendingOrders;
}

export function updateTradeTapeData(data) {
  return function (dispatch, getData) {};
}
// orderBook update and intialize data
export function updateBookBid(data) {
  const formattedOrderBook = formatOrderBook(data);
  return function (dispatch, getState) {
    let orderBook = getState().proTradeReducer.orderBookBid.slice(0);
    const getUpdatedOrderBid = getNewOrderBook(
      orderBook,
      formattedOrderBook,
      data.TriggerType
    );
    dispatch({
      type: ProTradeActionType.ORDER_BOOK_BID,
      payload: getUpdatedOrderBid,
    });
    dispatch(updateDepthChart());
  };
}
export function updateBookAsk(data) {
  const formattedOrderBook = formatOrderBook(data);
  return function (dispatch, getState) {
    let orderBook = getState().proTradeReducer.orderBookBid.slice(0);
    const getUpdatedOrderBid = getNewOrderBook(
      orderBook,
      formattedOrderBook,
      data.TriggerType
    );
    dispatch({
      type: ProTradeActionType.ORDER_BOOK_ASK,
      payload: getUpdatedOrderBid,
    });
    dispatch(updateDepthChart());
  };
}

export function orderBookBid(data) {
  const formattedOrderBook = formatOrderBook(data);
  return function (dispatch) {
    let maxVolume = getMaxVolume(formattedOrderBook);
    dispatch({
      type: ProTradeActionType.ORDER_BOOK_BID,
      payload: formattedOrderBook,
      maxVolumeBid: maxVolume,
    });
    dispatch(updateDepthChart());
  };
}

export function orderBookAsk(data) {
  const formattedOrderBook = formatOrderBook(data);
  return function (dispatch) {
    let maxVolume = getMaxVolume(formattedOrderBook);
    dispatch({
      type: ProTradeActionType.ORDER_BOOK_ASK,
      payload: formattedOrderBook,
      maxVolumeAsk: maxVolume,
    });
    dispatch(updateDepthChart());
  };
}
const updateDepthChart = () => {
  return (dispatch, getState) => {
    const orderBookBid = getState().proTradeReducer.orderBookBid;
    const orderBookAsk = getState().proTradeReducer.orderBookAsk;

    const depthChartAsk = getDepthChartData(orderBookAsk, "ask");
    const depthChartBid = getDepthChartData(orderBookBid, "bid");

    dispatch({
      type: ProTradeActionType.UPDATE_DEPTH_CHART,
      payload: _.sortBy([...depthChartBid, ...depthChartAsk], "price"),
    });
  };
};
const getDepthChartData = (orderBook, type) => {
  let newOrderBook = orderBook.map((item) => ({
    price: item.price,
    volume: item.volume,
    [`${type}TotalVolume`]: item.volume,
  }));
  let sortedOrderBook = [];

  if (type === "ask") {
    sortedOrderBook = _.orderBy(newOrderBook, "price", "asc");
  } else if (type === "bid") {
    sortedOrderBook = _.orderBy(newOrderBook, "price", "desc");
  }

  sortedOrderBook.forEach((singleItem, i) => {
    if (i >= 1) {
      singleItem[`${type}TotalVolume`] +=
        sortedOrderBook[i - 1][`${type}TotalVolume`];
    }
  });

  return sortedOrderBook;
};
export const ordersList = (data) => {
  return (dispatch) => {
    dispatch({
      type: ProTradeActionType.GET_MARKET_LIST,
      payload: data,
    });
  };
};

export const setTradeDetails = (data) => {
  return (dispatch) => {
    dispatch({
      type: ProTradeActionType.SET_TRADE_DETAIL,
      payload: data,
    });
  };
};

export const cancelOrder = (params) => {
  return async function (dispatch) {
    try {
      const reqData = params;
      const response = await NetworkManager.post(
        SERVER_URL + PROTRADE_URL.CANCEL_ORDER,
        reqData,
        true,
        {},
        true
      );
      if (response?.data?.data) {
        // dispatch({
        //   type: ProTradeActionType.SET_TRADE_DETAIL,
        //   payload: response.data.data
        // });
      }
      return response;
    } catch (err) {}
  };
};

export function setIsOpen(value) {
  return function (dispatch) {
    dispatch({
      type: ProTradeActionType.SET_IS_OPEN,
      payload: value,
    });
  };
}

export function getTradeHistory() {
  return async function (dispatch) {
    let orderStatus;
    try {
      orderStatus = await getOrderStatus(dispatch);
    } catch (err) {
      console.log(err);
    }
    try {
      const params = {
        side: "ALL",
        pair: "ALL",
        timestamp: Utility.generateTimestamp(),
        recvWindow: RECV_WINDOW,
        page: 1,
        count: 20,
      };
      const response = await NetworkManager.get(
        SERVER_URL + PROTRADE_URL.GET_TRADE_HISTORY,
        true,
        { ...params },
        true
      );
      if (response?.data?.status == "Success") {
        dispatch({
          type: ProTradeActionType.SET_TRADE_HISTORY,
          payload: response?.data?.data,
          orderStatus: orderStatus,
        });
      }
      return response;
    } catch (err) {}
  };
}

export function updateOrderBookSelection(side, rowData, index) {
  return function (dispatch, getState) {
    let orderBook =
      side === "bid"
        ? getState().proTradeReducer.orderBookBid
        : getState().proTradeReducer.orderBookAsk.slice(0).reverse();
    index = side === "bid" ? index : orderBook.length - (index + 1);
    let draftPayload;
    let currentData;
    for (let i = 0; i <= index; i++) {
      currentData = orderBook[i];
      if (draftPayload) {
        draftPayload = {
          price: currentData.price,
          volume: draftPayload.volume + currentData.volume,
        };
      } else {
        draftPayload = currentData;
      }
    }
    dispatch({
      type: ProTradeActionType.UPDATE_ORDERBOOk_SELECTION,
      payload: {
        price: draftPayload.price,
        volume: draftPayload.volume,
        side: side,
      },
    });
  };
}

export function getAddressBook(Currency) {
  let requestPayload = {
    Currency,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW,
  };
  return async function (dispatch) {
    try {
      return await NetworkManager.post(
        SERVER_URL + ADDRESS_BOOK_URL.GET_ADDRESS_BOOK,
        requestPayload,
        true,
        {},
        true
      );
    } catch (err) {
      console.log(err);
    }
  };
}

export function addAddress(payload) {
  let requestPayload = {
    ...payload,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW,
  };
  return async function (dispatch) {
    try {
      return await NetworkManager.post(
        SERVER_URL + ADDRESS_BOOK_URL.ADD_ADDRESS_BOOK,
        requestPayload,
        true,
        {},
        true
      );
    } catch (err) {
      console.log(err);
    }
  };
}

export function deleteAddress(payload) {
  let requestPayload = {
    ...payload,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW,
  };
  return async function (dispatch) {
    try {
      return await NetworkManager.post(
        SERVER_URL + ADDRESS_BOOK_URL.DELETE_ADDRESS_BOOK,
        requestPayload,
        true,
        {},
        true
      );
    } catch (err) {
      console.log(err);
    }
  };
}

export function getOrderHistory(payload) {
  let requestPayload = {
    ...payload,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW,
  };
  return async function (dispatch) {
    try {
      return await NetworkManager.get(
        SERVER_URL + TRANSACTION_HISTORY_URL.GET_TRANSACTION_HISTORY,
        true,
        requestPayload,
        true
      );
    } catch (err) {
      console.log(err);
    }
  };
}

async function getOrderStatus(dispatch) {
  const response = dispatch(
    getOrderHistory({
      pair: "ALL",
      side: "ALL",
      count: 100,
    })
  );
  let rows;
  let orderStatus;
  let responseData;
  try {
    responseData = await response;
    if (responseData.data.status === Response_Status.SUCCESS) {
      rows = responseData.data.data.rows;
      orderStatus = processOrderStatus(rows);
    }
  } catch (err) {
    console.log(err);
  }
  return orderStatus;
}

function processOrderStatus(orderStatus) {
  if (orderStatus) {
    let filteredOrders = {};
    Array.isArray(orderStatus) &&
      orderStatus.forEach((order) => {
        if (order.orderStatus !== "Pending") {
          filteredOrders[order.orderId] = order.orderStatus;
        }
      });
    return filteredOrders;
  }
}

import React, { useState } from "react";
import Dropdown from "../Dropdown/dropdown";
import InputBox from "../InputBox/inputbox";
import "./inputDropdown.css";

const InputDropdown = React.forwardRef(function InputDropdown(props, ref) {
  return (
    <div className={"inputdropdown " + (props.error ? "error-inputdropdown" : props.error)}>
      <InputBox
        parentClass={
          "inputdropdown-inputbox " +
          (props.inputExtraClass ? props.inputExtraClass : "")
        }
        type={props.inputType}
        value={props.inputValue}
        onChangeValue={(value, name) => {
          props.onChangeInputValue(value, name);
        }}
        placeholder={props.inputPlaceholder}
        name={props.inputName}
        defaultValue={props.defaultValue}
        minValue={props.minValue}
        maxValue={props.maxValue}
        onFocus={props.onFocus}
        ref={ref}
        step={props.step}
      />
      <div className="divider"></div>
      <Dropdown
        extraClassName={
          "inputdropdown-ddbox " +
          (props.ddExtraClass ? props.ddExtraClass : "")
        }
        buttonTextClass="ddbox-toggle"
        options={props.options ? props.options : []}
        handleChange={(value, name) => {
          props.onChangeDDValue(value, name);
        }}
        selectedValue={props.selectedValue}
        placeholder={props.ddPlaceholder}
        name={props.ddName}
      />
    </div>
  );
})

export default InputDropdown;

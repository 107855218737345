import React, { Suspense, lazy, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import MarketData from "./datafeed/marketData";
import FormatMarketData from "./components/init-state/init-marketdata";
import SetSettingsData from "./components/init-state/init-settings";
import { socket } from "./realtime";
import GoldToken from "./pages/goldToken/goldToken";

const Home = lazy(() => import("./pages/Home/home"));
const LoginHome = lazy(() => import("./pages/LoginHome/LoginHome"));
const IdVerification = lazy(() =>
  import("./pages/idVerification/idVerification")
);
const signup = lazy(() => import("./pages/signup/signup"));
const Login = lazy(() => import("./pages/login/login"));
const forgetPassword = lazy(() =>
  import("./pages/forgetPassword/forgetPassword")
);
const setPassword = lazy(() => import("./pages/setPassword/setPassword"));
const verificationCode = lazy(() =>
  import("./pages/verificationCode/verificationCode")
);
const changePassword = lazy(() =>
  import("./pages/changePassword/changePassword")
);
const goldToken = lazy(() =>
  import("./pages/goldToken/goldToken")
);
const bankAccount = lazy(() => import("./pages/bankAccount/bankAccount"));
const chooseTransaction = lazy(() =>
  import("./pages/chooseTransaction/chooseTransaction")
);
const confirmTransaction = lazy(() =>
  import("./pages/confirmTransaction/confirmTransaction")
);
const History = lazy(() => import("./pages/history/history"));
const LastTransaction = lazy(() =>
  import("./pages/lastTransaction/lastTransaction")
);
const Deposit = lazy(() => import("./pages/deposit/deposit"));
const WithDraw = lazy(() => import("./pages/withDraw/withDraw"));
const Transfer = lazy(() => import("./pages/transfer/transfer"));
const OtpAuth = lazy(() => import("./pages/otpAuth/otpAuth"));
const Order = lazy(() => import("./pages/order/order"));
const ProTrade = lazy(() => import("./pages/proTrade/proTrade"));
const AddressBook = lazy(() => import("./pages/addressBook/addressBook"));
const AddressManagement = lazy(() =>
  import("./pages/addressManagement/addressManagement")
);
const ApiKey = lazy(() => import("./pages/apiKey/apiKey"));

function App() {
  const checkAccessToken = () => {
    let token = sessionStorage.getItem("login_token");
    if (token) {
      if (JSON.parse(token).access_token) {
        return true;
      } else {
        return false;
      }
    } else return false;
  };

  const AuthRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        checkAccessToken() ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          )
      }
    />
  );


  return (
    <React.Fragment>
      <MarketData />
      <FormatMarketData />
      <SetSettingsData />
      <HashRouter>
        <Suspense fallback={<div className="page-loader">
          <div className="load-wrap">
            <img src="assets/images/loader.png" />
          </div>
        </div>}>
          <Switch>
            <Route
              exact
              path={Routes.ID_VERIFICATION}
              component={IdVerification}
            />
            <Route exact path={Routes.LOGIN} component={Login} />
            <Route
              exact
              path={Routes.VERIFICATION_CODE}
              component={verificationCode}
            />
            <Route exact path={Routes.SIGN_UP} component={signup} />
            <Route exact path={Routes.SET_PASSWORD} component={setPassword} />
            <Route exact path={Routes.GOLDTOKEN} component={GoldToken} />
            <Route
              exact
              path={Routes.FORGET_PASSWORD}
              component={forgetPassword}
            />
            <Route
              exact
              path={Routes.CHANGE_PASSWORD}
              component={changePassword}
            />
            <Route
              exact
              path={Routes.CHOOSE_TRANSACTION}
              component={chooseTransaction}
            />
            <Route
              exact
              path={Routes.CONFIRM_TRANSACTION}
              component={confirmTransaction}
            />
            <Route exact path={Routes.BANK_ACCOUNT} component={bankAccount} />
            <Route exact path={Routes.HISTORY} component={History} />
            <Route
              exact
              path={Routes.LAST_TRANSACTION}
              component={LastTransaction}
            />
            <Route exact path={Routes.DEPOSIT} component={Deposit} />
            <Route exact path={Routes.WITHDRAW} component={WithDraw} />
            <Route exact path={Routes.TRANSFER} component={Transfer} />
            <Route exact path={Routes.OTP_AUTH} component={OtpAuth} />
            <Route exact path={Routes.ORDER} component={Order} />
            <Route exact path={Routes.PRO_TRADE} component={ProTrade} />
            <Route exact path={Routes.ADDRESS_BOOK} component={AddressBook} />
            <Route
              exact
              path={Routes.ADDRESS_MANAGEMENT}
              component={AddressManagement}
            />
            <Route exact path={Routes.APIKEY} component={ApiKey} />
            <AuthRoute exact path={Routes.LOGINHOME} component={LoginHome} />
            <Route exact path={Routes.HOME} component={Home} />
          </Switch>
        </Suspense>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;

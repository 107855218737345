import initalState from "./common/initalState";
import proTradeActionType from "../actions/actionTypes/proTradeActionType";

export default function proTradeReducer(
  state = initalState.proTradeReducer,
  action
) {
  switch (action.type) {
    case proTradeActionType.SET_TRADING_PAIR:
      return {
        ...state,
        tradingPair: action.payload
      };
    case proTradeActionType.ORDER_BOOK_ASK:
      return {
        ...state,
        orderBookAsk: action.payload,
        maxVolumeAsk: action.maxVolumeAsk
      };
    case proTradeActionType.ORDER_BOOK_BID:
      return {
        ...state,
        orderBookBid: action.payload,
        maxVolumeBid: action.maxVolumeBid
      };
    case proTradeActionType.TRADE_TAPE_DATA:
      return {
        ...state,
        tradeTape: action.payload
      };
    case proTradeActionType.GET_ORDERS_LIST: 
      return {
        ...state,
        coinListData: action.payload
      };
    case proTradeActionType.UPDATE_DEPTH_CHART: 
      return {
        ...state,
        depthChart:action.payload
      }
    case proTradeActionType.SET_TRADE_DETAIL: 
      return {
        ...state,
        tradeDetail: action.payload
      };

    case proTradeActionType.GET_ORDERS_LIST: 
      return {
        ...state,
        coinListData: action.payload
      };

    case proTradeActionType.SET_IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };

    case proTradeActionType.SET_TRADE_DETAIL: 
      return {
        ...state,
        tradeDetail: action.payload
      };
    case proTradeActionType.SET_TRADE_HISTORY: 
      return {
        ...state,
        tradehistory: action.payload,
        orderStatus: action.orderStatus
      };
    case proTradeActionType.SET_ORDER_HISTORY: 
      return {
        ...state,
        orderhistory: action.payload
      };
    case proTradeActionType.UPDATE_ORDERBOOk_SELECTION:
      return {
        ...state,
        orderBookSelection: action.payload
      }
    default:
      return {
        ...state
      };
  }
}

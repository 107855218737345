import React, {useEffect, useState} from "react";
import "./goldToken.css";
import Header from "./../../components/resuable/header/header";
import  Button  from "../../components/ui-kit/Button/button";
import { Inputbox, Modal, Toasts, Loading} from "../../components/ui-kit";
import * as tradeGoldAction from "../../actions/tradeGoldAction";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Countdown from 'react-countdown';
import {Routes} from "../../routes";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Line } from 'rc-progress';
import {REGEX} from "../../utilities/constants";
import Footer from "../../components/resuable/footer/footer";
export function GoldToken(props) {
    const [state, setState] = useState({
        isOpen :false,
        startDate: Date.now(),
        setEmail: "",
        emailErr: ""
    })
    const [modalTime, setModalTime] = useState(false)
    const [endDate, setEndDate] = useState("2020 oct 20")
    const [complete, setComplete] = useState(false)
    const [balance, setBalance] = useState(0);
    const [gold, setGold] = useState("");
    const [convert, setConvert] = useState(0)
    const [minValue, setMinValue] = useState(0)
    const [qrPath, setQRPath] = useState("")
    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(2)
    function updateTime() {
        if (minutes === 0 && seconds === 0) {
            setSeconds(0);
            setMinutes(2);
            setState({
                ...state,
                isOpen:false
            })
            props.balanceTradeGold().then((res)=>{
                setBalance(res?.data?.result);
            })
            props.convertTradeGold().then(res=>{
                setGold(res?.data?.result);
            })  
        }
        else {
          if (seconds == 0) {
            setMinutes(minutes => minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds => seconds - 1);
          }
        }
    }
    function reset(){
        setSeconds(0);
        setMinutes(2);
    }
    useEffect(() => {
        const token = setTimeout(updateTime, 1000)
        return function cleanUp() {
            clearTimeout(token);
        }
    })  
    useEffect(()=> {
        props.balanceTradeGold().then((res)=>{
            setBalance(res?.data?.result);
        })
        props.convertTradeGold().then(res=>{
            setGold(res?.data?.result);
        })    
    },[])

        const salesCountDown = (new Date(endDate) - Date.now()) ;       
        const goldPurchase = ({ total, days, hours, minutes, seconds, completed }) => {
        if(completed){
            setComplete(true)
            setEndDate("2021 Jan 15")
        }
        if (completed){
            return <ul className="time-count start">
            <li>
            <b>{days}</b>
                <span>Day</span>
            </li>
            <li>
            <b>{hours}</b>
                <span>Hours</span>
            </li>
            <li>
            <b>{minutes}</b>
                <span>Minutes</span>
            </li>
            <li>
            <b>{seconds}</b>
                <span>Seconds</span>
            </li>
        </ul>
        }else {
            // Render a countdown
            return <ul className={"time-count start " +(complete? "color-count": "")}>
            <li>
            <b>{days}</b>
                <span>Day</span>
            </li>
            <li>
            <b>{hours}</b>
                <span>Hours</span>
            </li>
            <li>
            <b>{minutes}</b>
                <span>Minutes</span>
            </li>
            <li>
            <b>{seconds}</b>
                <span>Seconds</span>
            </li>
        </ul>
        }
        };
    
        function onCopyAmount() {
            Toasts.success("copied");
        }
        
        function progressBar(){
            var percent = gold?.sold / 500000 * 100;
            return percent ? percent: "0"

        }  
        console.log(minutes, seconds)
        return(
            <div className="gold-token-wrapper">
                <Toasts />
                <Header />
                <div className="container-fluid">
                    <div className="top-section">
                    <div className="left-section">
                        <img src="assets/images/gold.png" alt="gold logo" />
                        {/* <a href={"https://androidapk.cointraders.io/docs/GOL.DWhitePaper.pdf"} download /> */}
                        <Button
                        button_type="btn-tertiary"
                        text="Download Whitepaper"
                        extraClass="download-btn"
                        onClick={ () => {
                            window.open("https://androidapk.cointraders.io/docs/GOL.DWhitePaper.pdf", '_blank');

                        }}/>
                    </div>
                    <div className="right-section">
                        {props?.isLoggedIn === true && complete ? <span className="my-purchase">My Purchase : {balance?.balance} GOL.D</span> :""}
                        <strong>Gol.d Token</strong>
                        <p>At GOL.D we want to create a secure environment to buy and trade gold. GOL.D is a token backed by physical gold, our aim is democratizing the access through fractional ownership. Join us in this wonderful journey .</p>
                        <ul className="inline-list">
                            <li>
                                <span>Price</span>
                                <b>{convert ? convert + " " + "USDT" : "-"} </b>
                            </li>
                            <li>
                                <span>Minimum Token Purchase</span>
                                <b>100 Gol.d</b>
                            </li>
                            <li>
                                <span>First Issuance</span>
                                <b>5 M</b>
                            </li>
                            <li>
                                <span>Total Supply</span>
                                <b>50 M</b>
                            </li>
                        </ul>
                        {
                            props?.isLoggedIn === true && complete && <div className="token-purchased">
                            <b>Token purchased</b>
                                <em>{progressBar()+"%"}</em>
                                {/* <div className="token-purchased">
                                <b>Token purchased</b>
                                <em>25.88%</em>
                                <span className="progress-wrap">
                                    <i></i>
                                </span>
                                <strong>10/5M First Issuance</strong>
                            </div> */}
                                    <Line percent={progressBar()} strokeWidth="1" strokeColor="#A08337" />
                                <strong>{gold?.sold ? gold?.sold : 0 }/5M First Issuance</strong>
                            </div>
                        }
                        
                    </div>
                    </div>
                    <div className="mid-section">
                        <div className="left-section">
                        <h3 className="heading-with-underline">Digital Gol.d for everyone</h3>
                        <p>Gol.d is the oldest and most trusted store of value. It is limited supply and natural properties have made it the most sought-after precious metal since ancient times. Gold served as the backbone of monetary policy for half a century before governments around the world moved away from the “Gold Standard”. However, what has not changed is that gold perseveres as the safe haven for billions of people during times of uncertainty, as seen time and again through geo-political issues, hyper-inflation or devaluation and most recently the global Covid-19 pandemic.</p>
                        <p>GOL.D is a gold digital token where each GOL.D token represents one troy ounce of real Gold. Customers can initiate a process to redeem the GOL.D tokens for physical gold that can be secured from </p>
                        <ul className="digital-everyone">
                            <li>A syndicate of international mines that GOL.D has close ties to </li>
                            <li>The international gold markets </li>
                            <li>Coin Trader Ltd., a Bahamas based digital asset issuance and exchange that will provide the technical backbone and operations for the project.</li>
                        </ul>
                        <ul className="blockchain-list">
                            <li>
                                <span>Blockchain</span>
                                <strong>ERC 20 protocol</strong>
                            </li>
                            <li>
                                <span>Total supply</span>
                                <strong>50M tokens</strong>
                            </li>
                            <li>
                                <span>First issuance</span>
                                <strong>ERC 20 protocol</strong>
                            </li>
                            <li>
                                <span>Lock In period</span>
                                <strong>Yes (90 days)</strong>
                            </li>
                            <li>
                                <span>Min Amount Redeemable</span>
                                <strong>150 GOL.D Tokens</strong>
                            </li>
                            <li>
                                <span>100 GOL.D token</span>
                                <strong>1 Troy Ounce (31.1034768 grams)</strong>
                            </li>
                            <li>
                                <span>Sales start date</span>
                                <strong>1-Dec-2020 00:00 UTC</strong>
                            </li>
                            <li>
                                <span>Sales end date</span>
                                <strong>15-Jan-2021 23:59 UTC</strong>
                            </li>
                        </ul>
                        </div>
                        <div className="right-section">
                            <b>{complete ? "Token sale ends on" : "Token Sales in"}</b>
                          
                                <Countdown 
                                    date={state.startDate + salesCountDown} 
                                    renderer={goldPurchase} />
                            
                            
                            <strong>{!complete?"Start date : 1-Dec-2020 00:00 UTC":""}</strong>
                            
                            {
                               complete ? 
                                <div className="time-end-bottom">
                                <div className="purchase">
                                    <label>I want to purchase</label>
                                    <Inputbox
                                    placeholder="Enter amount (Gol.d)" 
                                    type={"number"}
                                    onChangeValue={(val)=>{
                                            var goldPrice = gold?.value;
                                            var calculation = goldPrice * val;
                                            setMinValue(val)
                                            setConvert(calculation) 
                                    }}
                                    />
                                    <span className="red-color">Minimum amount :100 GOL.D</span>
                                </div>
                                <div className="currency">
                                    <label>USDT</label>
                                   <Inputbox
                                    placeholder="USDT Value" 
                                    value={convert}
                                    onChangeValue={()=>{}}
                                    />
                                </div>
                                <Button
                                button_type="btn-primary"
                                text="Buy Now" 
                                disabled={minValue < 100}
                                onClick={()=>{
                                    reset();
                                    if(props?.isLoggedIn === true && props?.customerDetails?.kycStatus == "Approved"){
                                        props.PagePush("","")
                                        props.addressGenerate().then(res=>{
                                            setQRPath(res?.data?.result)
                                            setModalTime(true)
                                        });
                                        setState({
                                            ...state,
                                            isOpen: true
                                        })
                                    }else{
                                        if(props?.isLoggedIn === false){
                                            props.PagePush("loginPush","")
                                            props.history.push(Routes.LOGIN)
                                        }else if(props?.customerDetails?.kycStatus !== "Approved"){
                                            props.PagePush("","kycPush")
                                            props.history.push(Routes.ID_VERIFICATION)
                                        }
                                    }
                                }}
                                />
                            </div> : <div className="time-count-bottom">
                                <label>Get notification about this project</label>
                                <Inputbox
                                placeholder="Enter your email here" 
                                value={state?.setEmail}
                                onChangeValue={(val)=>{
                                    
                                    setState({
                                        ...state,
                                        setEmail: val,
                                        emailErr:""
                                    })
                                }}/>
                                
                                <Button
                                button_type="btn-primary"
                                text="Notify me" 
                                disabled={!state.setEmail}
                                onClick={()=>{
                                        if(!(REGEX.email.test(state?.setEmail))){
                                            setState({
                                                ...state,
                                                emailErr: "Invalid Email"
                                            })
                                        }else if(state?.setEmail?.length === 0){
                                            setState({
                                                ...state,
                                                emailErr: "Email can't be empty"
                                            })
                                        }else{
                                            props.sendNotifyEmail(state?.setEmail)
                                            .then(res=>{
                                                if(res?.data?.status === "success"){
                                                    Toasts.success("Thanks for the subscription, we will keep you posted")
                                                    setState({
                                                        ...state,
                                                        emailErr: "",
                                                        setEmail: ""
                                                    })
                                                }else{
                                                    Toasts.success("Thanks for the subscription, we will keep you posted")
                                                }
                                            }).catch(err=>{
                                                console.log(err)
                                            })
                                        }
                                        
                                        
                                }}/>
                                {
                                    state?.emailErr?.length > 0 ? <div className="email-error-field">{state?.emailErr}</div> : ""
                                }
                            </div>
                                
                            }
                            
                        </div>
                    </div>
                </div>
                <Footer />
                <Modal
                showModal={state.isOpen}
                onClose={()=>{
                    setState({
                        ...state,
                        isOpen:false
                    })
                }}>
                <React.Fragment>
                <div className="token-deposit-modal">
                    <div className="top-section">
                        <strong>
                        To purchase <em>{minValue} Gol.d</em> token please deposit <em>{convert?convert: "-"} USDT</em> in below address
                        </strong>
                        <span>Your Purchases : <b>{balance?.balance} GOL.D</b></span>
                    </div>
                    <div className="mid-section">
                        <b>Deposit</b>
                        <strong>Scan QR Code</strong>
                    
                        {/* <Countdown 
                            date={state.startDate + modalTime} 
                            renderer={modalCountDown} 
                            key={10}    
                        /> */}
                        <div className="time-limit">
                            <span>
                            <b>{minutes}</b>
                                <em>Minutes</em>
                            </span>
                            <span>
                            <b>{seconds}</b>
                                <em>Seconds</em>
                            </span>
                        </div>
                        <div className="qr-wrap">
                            <span></span>
                            {qrPath?.qr_url ? <img src={qrPath?.qr_url ? qrPath.qr_url : "assets/images/qr.svg"} /> : <span><Loading /> </span>}
                        </div>
                        <div className="deposit-bottom-section">
                            <CopyToClipboard text={convert?convert: "-" +  "USDT"} onCopy={onCopyAmount}>
                            <span>{convert} USDT <em>Copy amount</em></span>
                            </CopyToClipboard>
                            <CopyToClipboard text={qrPath?.address ? qrPath?.address : "-" } onCopy={onCopyAmount}>
                            <span>{qrPath?.address ? qrPath?.address : "-" }<em>Copy address</em></span>
                            </CopyToClipboard>
                            <strong>Processing time depends on Blockchain confirmation</strong>
                            <Button
                            button_type="btn-primary"
                            text="Done"
                            onClick={()=>{
                                setState({
                                    ...state,
                                    isOpen:false
                                })
                            }}
                            />
                        </div>
                    </div>
                </div>
                </React.Fragment>
            </Modal>
            </div>
        )
}

const mapStateToProps = (state) =>{
    return {
    isLoggedIn: state.signinReducer.isLoggedIn,
    customerDetails: state.homeReducer.customerDetails,

}
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(tradeGoldAction, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(GoldToken);
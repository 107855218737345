import { REGEX, PASSWORD_REGEX } from "./constants";

var cloneDeep = require("lodash/cloneDeep");
var isEqual = require("lodash.isequal");
export class FormControl {
  constructor(
    label,
    value,
    validators = {
      required: false,
      minLength: 0,
      maxLength: undefined,
      onlyNumber: false,
      onlyDecimal: false,
      email: false,
      password: false,
      telephone: false
    },
    error = null,
    touched = false,
    showPassword = false
  ) {
    this.label = label;
    this.value = value;
    this.validators = validators;
    this.error = error || Utility.getValidationError(this);
    this.touched = touched;
    this.showPassword = showPassword;
  }
}

class Utility {
  /**
   * Checks whether the Image file formats are supported
   *
   * @param {string} filename
   * @return {boolean}
   */
  static isFileFormatSupported(filename) {
    let supportedFormats = ["png", "jpeg", "jpg"];
    let ext = filename.split(".").pop();
    return supportedFormats.some(extension => {
      return extension === ext;
    });
  }

  /**
   *
   * @param {string} currency
   * @return {string} imageUrl
   */

  static getCurrencyLogo(currency) {
    if (currency) {
      return `https://cyptoassets.blob.core.windows.net/cryptologo/${currency.toLowerCase()}.png`;
    }
  }
  /**
   * generates the error message for a FormControl instance
   *
   * @param {FormControl} formControl
   * @return {string} errorMessage
   *
   *
   */
  static getValidationError(formControl) {
    if (formControl.validators.required && !formControl.value) {
      return `${formControl.label} is required`;
    }
    if (
      formControl.validators.minLength &&
      formControl.value &&
      formControl.value.length < formControl.validators.minLength
    ) {
      return `${formControl.label} requires minimum ${formControl.validators.minLength} characters`;
    }
    if (
      formControl.validators.maxLength &&
      formControl.value &&
      formControl.value.length > formControl.validators.maxLength
    ) {
      return `${formControl.label} requires exceeds ${formControl.validators.maxLength} characters`;
    }
    if (
      formControl.validators.email &&
      formControl.value &&
      !REGEX.email.test(formControl.value)
    ) {
      return `Invalid ${formControl.label}`;
    }
    if (
      formControl.validators.password &&
      formControl.value &&
      !PASSWORD_REGEX.password.test(formControl.value)
    ) {
      return "Password must be at least 8 characters with 1 upper case and 1 number";
    }

    return null;
  }

  /**
   * Copies an instance of an object
   *
   * @param Object obj
   * @return obj New Object
   */
  static deepCopyObject(objectpassed) {
    return cloneDeep(objectpassed);
  }

  /**
   *
   * @param Object object1
   * @param Object object2
   * @return boolean
   */

  static compareObjects(object1, object2) {
    return isEqual(object1, object2);
  }

  /**
   *
   * Geneartes a timestamp in utc format
   * @return Date(in milliseconds)
   */
  static generateTimestamp() {
    return Math.floor(new Date().getTime() / 1000);
  }

  /**
   *
   * Compares two password and returns boolean value
   * @param {FormControl} password
   * @param {FormControl} confirmPassword
   *
   * @return boolean
   */

  static isPasswordEqual(password, confirmPassword) {
    return password.value.length &&
      confirmPassword.value.length &&
      password.value === confirmPassword.value
      ? true
      : false;
  }

  /**
   *
   * Formats money using commas
   * @param {String} money
   *
   * @return String (formatted value)
   *
   */

  static formatMoney(money, currency = "$") {
    money = money ? money : 0;
    if (money < 0) {
      let value = money.split(".");
      let realMoney = value[0];
      let formattedValue = "";
      for (let i = realMoney.length; i > 0; i -= 3) {
        if (i <= 3) {
          formattedValue = realMoney.slice(0, i) + formattedValue;
        } else {
          formattedValue = "," + realMoney.slice(i - 3, i) + formattedValue;
        }
      }
      formattedValue = value[1]
        ? formattedValue + "." + value[1]
        : formattedValue;
      return currency + formattedValue;
    } else {
      return "$0.00";
    }
  }

  /**
   *
   * @param {string} currency
   * @return {string} Image url
   */

  static getCurrencyLogo(currency) {
    if (currency) {
      return `https://cyptoassets.blob.core.windows.net/cryptologo/${currency.toLowerCase()}.png`;
    }
  }

  /**
   *
   * @return {Number} Random number between 1 and 999
   */

  static generateRandomNumber() {
    return Math.floor(Math.random() * 999) + 1;
  }

  /**
   *
   * @param {string} str
   * @return {string} returns a string with decapitalized first letter of string
   */

  static deCapitalizeFirstLetter(str) {
    return str.slice(0, 1).toLowerCase() + str.slice(1);
  }

  static capitaliseString(str) {
    return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
  }

  static countPlaces(value) {
    value = value.toString();
    if (value.indexOf("e") !== -1) {
      let exponent = parseInt(value.split("-")[1], 10);
      return exponent ? exponent : 0;
    } else {
      let afterPlacesValue = value.split(".")[1];
      return afterPlacesValue?.length ? afterPlacesValue.length : 0;
    }
  }

  static maskEmail(value) {
    if (value) {
      let email = value.split("@");
      if (email[0]) {
        let emailPrefix = email[0];
        let maskedString = "";
        for (let i = 0; i < emailPrefix.length; i++) {
          if ((i+1) % 2 == 0) {
            maskedString += "*";
          } else {
            maskedString += emailPrefix.charAt(i);
          }
        }
        return maskedString + "@" + (email[1] ? email[1] : "");
      }
    }
  }
}

export default Utility;

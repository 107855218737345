export const SERVER_URL = "https://node1.cointraders.io";
export const SERVER_URL_2 = "https://token.cointraders.io";

export const WEB_SOCKET_OPTIONS = {
  BASE_URL: "wss://node1.cointraders.io/"
};

export const MARKET_VIEW_URL = {
  MARKET_LIST_VIEW: "/api/GetSettings"
};

const COINTRADER = "/cointrader";

export const ONBOARDING_URL = {
  SIGN_IN: COINTRADER + "/sign-in",
  VERIFY_OTP: COINTRADER + "/sign-in-verify-otp",
  RESEND_OTP: COINTRADER + "/signin-resend-otp",
  SIGN_UP: COINTRADER + "/request-otp",
  SIGN_UP_VERIFICATION: COINTRADER + "/sign-up",
  REQUEST_OTP: COINTRADER + "/request-otp",
  FORGET_PASSWORD: COINTRADER + "/forgot-password",
  GET_CUSTOMER_DETAILS: "/api/GetProfile",
  GET_BALANCE_DETAILS: "/api/GetBalance",
  GET_CRYPTO_PRICE: "/api/get_crypto_price"
};

export const SETTINGS_URL = {
  GET_SETTINGS: "/api/GetSettings",
  CHANGE_PASSWORD: "/api/ChangePassword",
  CHANGE_PASSWORD_REQUEST_OTP: "/api/RequestChangePasswordOTP",
  GET_GAUTH_QRCODE: "/api/GAuth_Enable_Request",
  ENABLE_GAUTH: "/api/GAuth_Set_Enable",
  DISABLE_GAUTH: "/api/GAuth_Disable_Request",
  CURRENCY_SETTINGS: "/api/CurrencySettings"
};

export const KYC_DETAILS_URL = {
  GET_KYC_DETAILS: "/api/GOKYC_Get_Kyc_Form",
  SEND_KYC_DETAILS: "/api/GOKYC_Submit_KYC_Form?level=1"
};

export const TRANSACTION_HISTORY_URL = {
  GET_TRANSACTION_HISTORY: "/api/OrderHistory",
  GET_DEPOSIT_HISTORY: "/api/GetDeposits",
  GET_FIAT_DEPOSIT_HISTORY:
    "/api/List_Fiat_Manual_Deposit_Requests?currency=NGN",
  GET_WITHDRAWAL_HISTORY: "/api/GetWithdrawals",
  INTERNALTRANSFER_REQUEST_OTP:
    "/cointrader/request-withdrawal-otp/internal-transfer",
  GET_INTERNALTRANSFER_HISTORY: "/cointrader/internal-transfer"
};

export const TRANSFER_URL = {
  GET_CUSTOMER_LIST: "/cointrader/search-customer-address",
  GET_INTERNAL_TRANSFER_OTP:
    "/cointrader/request-withdrawal-otp/internal-transfer",
  PROCESS_INTERNAL_TRANSFER: "/cointrader/internal-transfer"
};
export const WITHDRAWAL_URL = {
  CRYPTO_WITHDRAWAL: COINTRADER + "/crypto-withdrawal",
  CRYPTO_WITHDRAWAL_OTP:
    COINTRADER + "/request-withdrawal-otp/crypto-withdrawal",
  FIAT_WITHDRAWAL_OTP: COINTRADER + "/request-withdrawal-otp/fiat-withdrawal",
  FIAT_WITHDRAWAL: COINTRADER + "/fiat-withdrawal"
};

export const OMS_URL = {
  PLACE_ORDER: "/api/PlaceOrder"
};

export const DEPOSIT = {
  BANK_LIST: "/api/List_Fiat_BanksList/USD/",
  DEPOSIT_URL: "/api/GenerateAddress",
  FIAT_DEPOSIT: "/api/Add_Fiat_Manual_Deposit_Request"
};

export const PROTRADE_URL = {
  CANCEL_ORDER: "/api/CancelOrder",
  GET_TRADE_HISTORY: "/api/TradeHistory"
};

export const ADDRESS_BOOK_URL = {
  GET_ADDRESS_BOOK: "/api/Get_AddressBook",
  ADD_ADDRESS_BOOK: "/api/Add_AddressBook",
  DELETE_ADDRESS_BOOK: "/api/Delete_AddressBook"
};

export const MANAGE_API_KEY_URL = {
  GET_MANAGE_API_LIST: "/api/ListApiKey",
  DELETE_API_KEY: "/api/DeleteApiKey",
  GENERATE_KEY: "/api/GenerateApiKey"
};
export const TRADE_GOLD_TOKEN = {
  LOGIN : "/trade/login/",
  BALANCE: "/trade/balance/",
  CONVERT: "/trade/convert/",
  TOKEN_GENERATE: "/trade/address/",
  SEND_EMAIL:"/trade/email/"
}
import NetworkManager from "../utilities/networkManager";
import { SERVER_URL, SETTINGS_URL } from "../utilities/apiRoutes";
import Utility from "../utilities/utility";
import { RECV_WINDOW } from "../utilities/constants";

export function changePassword({ oldPassword, newPassword, otp }) {
  const requestData = {
    oldPassword,
    newPassword,
    otp,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW
  };
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + SETTINGS_URL.CHANGE_PASSWORD,
        requestData,
        true
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function requestChangePasswordOtp() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + SETTINGS_URL.CHANGE_PASSWORD_REQUEST_OTP,
        {},
        true
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function generateGAuthQRCode() {
  return async function (dispatch) {
    try {
      const response = await NetworkManager.get(
        SERVER_URL +
          SETTINGS_URL.GET_GAUTH_QRCODE +
          `?timeStamp=${Utility.generateTimestamp()}&recvWindow=${RECV_WINDOW}`,
        true
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function enableGoogleAuth({ gAuthCode }) {
  const requestData = {
    GAuth_Code: gAuthCode,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW
  };
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + SETTINGS_URL.ENABLE_GAUTH,
        requestData,
        true,
        {},
        true
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

export function disableGoogleAuth({ gAuthCode }) {
  const requestData = {
    GAuth_Code: gAuthCode,
    timestamp: Utility.generateTimestamp(),
    recvWindow: RECV_WINDOW
  };
  return async function (dispatch) {
    try {
      const response = await NetworkManager.post(
        SERVER_URL + SETTINGS_URL.DISABLE_GAUTH,
        requestData,
        true,
        {},
        true
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };
}

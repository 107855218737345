import React from "react";
import propTypes from "prop-types";
import "./radio-button.css";

function RadioButton(props) {
    return (
        <div className="custom-radio-button">
            <input id={props.id} type="radio" name={props.name} value={props.value} checked={props.selectedValue === props.value} onChange={props.onChange}/>
            <label htmlFor={props.id} className={"custom-label label-" + (props.type ? props.type : "primary")}>
               {props.label}
            </label>
        </div>
    )
}

RadioButton.propTypes = {
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    value: propTypes.string.isRequired,
    selectedValue: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    label: propTypes.string,
    type: propTypes.string
}

export default RadioButton;
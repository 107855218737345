import React, { useEffect } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as homeAction from "../../actions/homeAction";

function SetSettingsData(props) {
    useEffect(() => {
        props.getSettingsData();
    }, [])
    return(<React.Fragment/>);
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(Object.assign({}, homeAction), dispatch);
}

export default connect(null, mapDispatchToProps)(SetSettingsData)
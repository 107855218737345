import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";


const fallbackLang = {
    'zh': ['zh-CN'],
    'default': ['en']
};
const availableLanguages = ["en", "zh-CN"];


i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        detection: {
            order: ["localStorage", "navigator"],
            lookupLocalStorage: 'i18nextLng',
        },
        fallbackLng: fallbackLang,
        preload: ["en", "zh-CN"],
        debug: true,
        whitelist: availableLanguages,
        supportedLngs: availableLanguages,
        interpolation: {
            escapeValue: true
        },
        returnObjects: true
    }, (err) => {
        console.log(err);
    });

export default i18n;

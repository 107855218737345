import React from "react";
import OtpInput from "react-otp-input";
import propTypes from "prop-types";
import "./otp-input.css";

function CustomOtpInput(props) {

    function onChange(value) {
        props.onChange(value, props.name);
    }
    
    return(
        <React.Fragment>
            <OtpInput numInputs={6}
                      onChange={onChange}
                      separator={<span>&nbsp;</span>}
                      isInputNum={true}
                      hasErrored={props.error}
                      value={props.value}
                      isDisabled={props.disabled}
                      containerStyle={"otp-wrapper " + (props.extraContainerClass ? props.extraContainerClass : "")}
                      errorStyle="otp-error-field"
                      inputStyle={"otp-input " + (props.extraInputClass? props.extraInputClass : "")}
                      focusStyle="otp-input-focus"
                      shouldAutoFocus={true}/>
        </React.Fragment>
    )
}

CustomOtpInput.propTypes = {
    onChange: propTypes.func.isRequired,
    error: propTypes.bool,
    value: propTypes.string,
    name: propTypes.string,
    extraInputClass: propTypes.string,
    extraContainerClass: propTypes.string
}

export default CustomOtpInput;
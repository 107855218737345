const HOME_ACTION = {
  GET_MARKET_LIST: "GET_MARKET_LIST",
  SET_SETTINGS_DATA: "SET_SETTINGS_DATA",
  SET_CUSTOMER_DETAILS: "SET_CUSTOMER_DETAILS",
  SET_STATS_LIST: "SET_STATS_LIST",
  QRCODE: "QRCODE",
  BANK_LIST: "BANK_LIST",
  FIAT_DEPOSIT: "FIAT_DEPOSIT",
  SET_CURRENCY_SETTINGS: "SET_CURRENCY_SETTINGS",
  WALLET_VALUES: "WALLET_VALUES",
  SET_WALLET_INFO: "SET_WALLET_INFO",
  SET_BUY_SELL_INFO: "SET_BUY_SELL_INFO",
  SET_CRYPTO_PRICE: "SET_CRYPTO_PRICE",
  SET_BALANCE_DETAILS: "SET_BALANCE_DETAILS",
  SET_MARKETPRICES_MAPPING: "SET_MARKETPRICES_MAPPING",
  SET_TRADETYPE: "SET_TRADETYPE"
};
export default HOME_ACTION;
